import React, { useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import Locale from "translations";
import GroupTable from "./GroupTable";

export default function ListGroups() {
	const { teamManagement } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const toggleButton = () => setIsOpen(!isOpen);

	return (
		<>
			<div className="col-md-12">
				<div className="headTeam rounded-right list-groups">
					<div className="row ">
						<div className="col">
							<button
								className="button-view btn-block p-2 pl-5 "
								onClick={toggleButton}
								type="button"
							>
								<span className=" rounded-right p-2 ">
									{!isOpen ? (
										<i className="fas fa-plus"></i>
									) : (
										<i className="fas fa-minus"></i>
									)}
								</span>
								إدارة البنك المركزي المصري
							</button>
						</div>
						<div className="col">
							<p className="p-2 text-center m-0  ">البنك المركزى المصري</p>
						</div>
						<div className="col">
							<p className="p-2 text-center m-0 ">
								<span className="rounded-circle num ">2</span>
								مستخدمين
							</p>
						</div>
						<div className="col">
							<ul className="p-2 mr-2 float-right">
								<li className="d-inline m-1 ">
									<a className="pointer text-gray">
										<i className="far fa-edit"></i>
									</a>
								</li>
								<li className="d-inline m-1">
									<a className="text-danger pointer">
										<i className="fas fa-trash-alt"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Collapse isOpen={isOpen}>
					<Card>
						<CardBody>
							{/* if length == 0 */}

							{/* <p className="text-muted mt-5 h1 text-center">
									<i className="fas h1 fa-exclamation-triangle "></i>
								</p>
								<div className="text-center pt-5">
									<i className="fas h1 fa-cog text-muted fa-spin"></i>
								</div> */}

							<GroupTable />
						</CardBody>
					</Card>
				</Collapse>
			</div>
		</>
	);
}
