import CheckBox from "components/shared/Chekbox";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function Filter({ activePage }) {
	const { teamManagement } = Locale;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				{activePage === 1 ? (
					<div className="filter-box">
						<SelectField
							hasLabel={false}
							value={""}
							placeholder={teamManagement.status}
							name="country"
							options={["countries"]}
							onChange={(e) => {}}
						/>
					</div>
				) : (
					""
				)}

				<div className="btn-boxs">
					{activePage === 1 ? (
						<button onClick={toggle}>{teamManagement.addUser}</button>
					) : (
						<button onClick={toggle}>{teamManagement.addGroup}</button>
					)}
				</div>
			</div>
			<Modal
				className={`add-model ${activePage === 2 ? "w-55" : ""} `}
				isOpen={modal}
				toggle={toggle}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">
						{activePage === 2
							? teamManagement.addGroup
							: teamManagement.addUser}
					</p>
				</div>

				<ModalBody>
					{activePage === 2 ? (
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-5">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="col-md-12">
							<div className="col-md-12">
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggle}>
						{teamManagement.add}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
