import React, { useState } from "react";
import Locale from "translations";

export default function User({ toggleChangePassword, user, toggle }) {
	const { teamManagement, users, Labs } = Locale;

	const [status, setStatus] = useState(user.status === "active" ? true : false);

	const toggleButton = async () => {
		// const res = await changeStatus(user.id);
		// if (res === 200) {
		// 	console.log("fetchStatus", res);
		// }
		setStatus(!status);
	};

	return (
		<>
			<tr key={user.id}>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td>Group One</td>
				<td>
					<i
						className={`far fa-2x ${
							status === true
								? "fa-check-circle text-success"
								: "text-danger fa-times-circle"
						}`}
					></i>
				</td>

				<td className="w-10 text-center">
					<button
						type="button"
						className="btn btn-link text-gray"
						onClick={() => toggle(user.id)}
					>
						<i className="far fa-edit"></i> {users.edit}
					</button>
				</td>
				<td className="align-middle pointer" onClick={toggleButton}>
					<a
						className={`link-company pointer ${
							status === true ? "text-danger" : "text-success"
						}`}
					>
						<i
							className={`fas fa-fw ${status === true ? "fa-stop" : "fa-play"}`}
						></i>
						{status === true ? `${Labs.stop}` : `${Labs.play}`}
					</a>
				</td>
				<td className="w-14 text-center">
					<button
						type="button"
						className="text-gray btn btn-link"
						onClick={toggleChangePassword}
					>
						<i className="fas fa-unlock-alt"></i> {users.changePassword}
					</button>
				</td>
				<td className="w-10 text-center">
					<button type="button" className="btn btn-danger">
						{users.remove}
					</button>
				</td>
			</tr>
		</>
	);
}
