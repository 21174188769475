import React from "react";
import Locale from "translations";

export default function User({ toggleModelEdit, toggleChangePassword }) {
	const { teamManagement, Labs, users } = Locale;
	return (
		<>
			<tr>
				<td>ahmed</td>
				<td>email@email.com</td>
				<td>مجموعة 1</td>
				<td>
					<i className="far fa-check-circle fa-2x text-success"></i>
				</td>

				<td className="w-10">
					<button
						type="button"
						onClick={toggleModelEdit}
						className="btn btn-link text-gray"
					>
						<i className="far fa-edit"></i> {teamManagement.edit}
					</button>
				</td>
				<td className="w-10">
					<button type="button" className="btn btn-link text-danger">
						<i className="fas fa-stop"></i>
						{Labs.stop}
					</button>
				</td>
				<td className="w-10">
					<button
						onClick={toggleChangePassword}
						type="button"
						className="text-gray btn btn-link "
					>
						<i className="fas fa-unlock-alt"></i> {Labs.changePassword}
					</button>
				</td>
				<td className="w-10">
					<button type="button" className="btn btn-danger">
						{users.remove}
					</button>
				</td>
			</tr>
		</>
	);
}
