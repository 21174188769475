import axios from "axios";

const lookupsUrl = process.env.REACT_APP_API_URL + "/api/central/lookups";

export const fetchCountries = async () => {
	return await axios
		.get(`${lookupsUrl}/countries`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const fetchCities = async (id) => {
	return await axios
		.get(`${lookupsUrl}/cities?country_id=${id}`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const fetchAreas = async (id) => {
	return await axios
		.get(`${lookupsUrl}/areas?city_id=${id}`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
