import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { listUsersForBranch } from "services/lab";
import Locale from "translations";
import ChangePassword from "./ChangePassword";
import User from "./User";

export default function ListUsers({ toggle }) {
	const { Labs, users } = Locale;
	const [modalChangePassword, setModalChangePassword] = useState(false);
	const toggleChangePassword = () =>
		setModalChangePassword(!modalChangePassword);

	const [user, setUser] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		async function fetchData() {
			const users = await listUsersForBranch(id);
			if (users.status === 200) {
				setUser(users.data.data.data);
			}
		}
		fetchData();
	}, []);

	const AllUsers =
		user.length > 0 &&
		user.map((user, index) => (
			<User
				modalChangePassword={modalChangePassword}
				toggleChangePassword={toggleChangePassword}
				user={user}
				index={index}
				toggle={toggle}
			/>
		));

	console.log("User", user);

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">{users.userName}</th>
									<th scope="col">{users.email}</th>
									<th scope="col">{users.groupName}</th>
									<th scope="col">{users.status}</th>
									<th className="text-center" colSpan="4" scope="col">
										{users.processes}
									</th>
								</tr>
							</thead>
							<tbody>
								<ChangePassword
									modalChangePassword={modalChangePassword}
									toggleChangePassword={toggleChangePassword}
								/>
								{AllUsers.length > 0 ? (
									AllUsers
								) : (
									<tr>
										<td colSpan="11">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{Labs.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
