import PasswordField from "components/shared/PasswordField";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
	changePasswordUserForLab,
	changeStatusLabUser,
	listLabsUsers,
} from "services/lab";
import Locale from "translations";
import User from "./User";

function ListUsers({ Users, labId, setUsers }) {
	const { companies, Labs, teamManagement, commons } = Locale;
	const [modalPassword, setModalPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [changePassword, setChangePassword] = useState({});
	const [userid, setUserId] = useState();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	useEffect(() => {
		/* change password api */
		const changePasswordUser = async () => {
			if (isFormValid(errors)) {
				const res = await changePasswordUserForLab(
					labId,
					userid,
					changePassword
				);
				if (res.status == 200) {
					togglePassword(0);
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		changePasswordUser();
	}, [isErrorLoaded]);

	const togglePassword = (e) => {
		setErrors({});
		setChangePassword({});
		setUserId(e);
		setModalPassword(!modalPassword);
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "password",
					value: changePassword.password,
				},
				{
					required: true,
					password: true,
					min: 8,
				}
			),
			...validate(
				{
					name: "password_confirmation",
					value: changePassword.password_confirmation,
				},
				{
					required: true,
					confirm:
						changePassword.password_confirmation !== changePassword?.password,
				}
			),
		});
	};

	const handlePassword = async () => {
		await checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const changeStatuslabUser = async (id, status) => {
		const res = await changeStatusLabUser(labId, id, status);
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			async function fetchData() {
				const users = await listLabsUsers(labId);
				if (users.status === 200) {
					setUsers(users.data.data.data);
					/*let meta = labs.data.meta;
				setRes(meta); */
				}
			}
			fetchData();
		}
	};
	const AllUsers =
		Users &&
		Users.length > 0 &&
		Users.map((user, index) => (
			<User
				user={user}
				labId={labId}
				togglePassword={togglePassword}
				changeStatuslabUser={changeStatuslabUser}
				/*key={"lab" + index}
				index={index}
				*/
				// removeItem={removeItem}
			/>
		));

	return (
		<>
			<div className="reservation-table">
				<table className="table table-striped mb-0">
					<thead>
						<tr className="bg-grey text-white">
							<th scope="col">{companies.userName}</th>
							<th scope="col">{companies.email}</th>
							<th scope="col">{companies.status}</th>
							<th colSpan="5" scope="col" className="text-center">
								{Labs.processes}
							</th>
						</tr>
					</thead>
					<tbody>
						{AllUsers && AllUsers.length > 0 ? (
							AllUsers
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-build__product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{Labs.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</table>

				{/* change password modal */}
				<Modal isOpen={modalPassword} toggle={togglePassword}>
					<ModalHeader toggle={togglePassword}>
						{Labs.changePassword}
					</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-md-12">
								<PasswordField
									type="text"
									label={teamManagement.password}
									placeholder={teamManagement.password}
									name="password"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												password: true,
												min: 8,
											}),
										});
										setChangePassword({
											...changePassword,
											password: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.password?.required ||
										errors?.password?.password ||
										errors?.password?.min
											? "danger"
											: ""
									}
									errors={errors?.password}
									value={changePassword.password}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									type="text"
									label={teamManagement.password_confirmation}
									placeholder={teamManagement.password_confirmation}
									name="password_confirmation"
									onChange={(e) => {
										setChangePassword({
											...changePassword,
											password_confirmation: e.target.value,
										});
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												confirm: e.target.value !== changePassword?.password,
											}),
										});
									}}
									color={
										errors?.password_confirmation?.required ||
										errors?.password_confirmation?.confirm
											? "danger"
											: ""
									}
									errors={errors?.password_confirmation}
									value={changePassword.password_confirmation}
								/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={handlePassword}>
							{commons.save}
						</Button>{" "}
						<Button color="secondary" onClick={togglePassword}>
							{commons.back}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	);
}

export default ListUsers;
