import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";

export default function EditUser({ modalEdit, toggleModelEdit }) {
	const { teamManagement } = Locale;

	return (
		<>
			<Modal
				className={`add-model`}
				isOpen={modalEdit}
				toggle={toggleModelEdit}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{teamManagement.addUser}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="col-md-12">
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.userName}
									placeholder={teamManagement.enterUserName}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.email}
									placeholder={teamManagement.enterEmail}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<SelectField
									type="text"
									label={teamManagement.groupName}
									placeholder={teamManagement.groupName}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.phoneNumber}
									placeholder={"0110000000000000"}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggleModelEdit}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggleModelEdit}>
						{teamManagement.edit}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
