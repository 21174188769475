import React from "react";
import Locale from "translations";
import Filter from "./Filter";
import List from "./List";

export default function BranchesTravelers() {
	const { Labs } = Locale;
	return (
		<>
			<section className="bg-gray py-3 medical-section">
				<div className="container-fluid">
					<Filter />
					<List />
				</div>
			</section>
		</>
	);
}
