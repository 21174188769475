export default function labsReducer(state, action) {
	switch (action.type) {
		case "setLoading":
			return { ...state, loading: action.payload };

		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "CurrentUser":
			localStorage.setItem("CurrentUser", JSON.stringify(state));
			return {
				...state,
			};
		case "login":
			// debugger;
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.payload.access_token);
			localStorage.setItem("userType", action.payload.user_type);
			localStorage.setItem(
				"permissions",
				JSON.stringify(action.payload.permissions)
			);
			return {
				...state,
				isAuth: action.isAuth,
				token: action.payload.access_token,
				userType: action.payload.user_type,
				permissions: action.payload.permissions,
			};

		case "logout":
			localStorage.clear();
			return {
				...state,
				isAuth: false,
				token: null,
				permissions: [],
			};

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
