import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";

export default function Filter({ filter, setfilter, statues }) {
	const { Labs, teamManagement, commons } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const genders = [
		{ value: "male", label: commons.male },
		{ value: "female", label: commons.female },
	];

	return (
		<>
			<div className="row align-items-center m-0">
				<div className="col-md-2">
					<TextField
						label={Labs.passportNumber}
						onChange={(e) => {
							setfilter({
								...filter,
								passport_number: e.target.value,
								page: 1,
							});
						}}
						value={filter.passport_number}
						placeholder={Labs.passportNumberPlaceHolder}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						label={Labs.nationalId}
						onChange={(e) => {
							setfilter({ ...filter, national_id: e.target.value, page: 1 });
						}}
						value={filter.national_id}
						placeholder={Labs.nationalIdPlaceholder}
					/>
				</div>

				<div className="col-md-2">
					<SelectField
						value={filter.gender}
						label={Labs.gender}
						placeholder={Labs.genderPlaceholder}
						name="gender"
						options={genders}
						onChange={(e) => {
							setfilter({ ...filter, gender: e, page: 1 });
						}}
					/>
				</div>

				<div className="col-md-2">
					<DatePickerField
						label={
							statues === "pending" ? Labs.applicationDate : Labs.issueDate
						}
						placeholder="YY-MM-DD"
						date={filter.request_date}
						onDateChange={(e) => {
							// moment(e._d).format("DD-MM-YYYY")
							setfilter({
								...filter,
								request_date: e,
								page: 1,
							});
						}}
						isOutsideRange={(day) => false}
					/>
				</div>
				<div
					className=" border p-2 rounded bg-white"
					role="button"
					onClick={() => {
						setfilter({
							national_id: "",
							passport_number: "",
							request_date: "",
							gender: null,
						});
					}}
				>
					<span>{commons.reset}</span>
					<i class="fas fa-sync-alt m-2"></i>
				</div>
			</div>
		</>
	);
}
