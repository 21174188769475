import React, { useState } from "react";
import Locale from "translations";
import Filter from "./Filter";
import ListGroups from "./groups/ListGroups";
import ListSections from "./sections/ListSections";
import ListUsers from "./users/ListUsers";
export default function View() {
	const { teamManagement, Labs, groups } = Locale;
	const [active, setActive] = useState(1);

	const [modal, setModal] = useState(false);
	const toggle = async (id) => {
		// debugger;
		// if (id) {
		// 	const res = await getBranch(id);
		// 	if (res.status === 200) {
		// 		const formatted = formatGetBranch(res.data.data);
		// 		setBranch(formatted);
		// 	}
		// }

		setModal(!modal);
	};

	return (
		<>
			<section className="bg-gray py-3 medical-section Users">
				<div className="container-fluid">
					<div className="col-md-12">
						<div className="row">
							<div className="itemBoxCircle">
								<div className="boxCircle d-flex align-items-center">
									<span className="counter">6</span>
									<span className="circle">
										<i className="fas fa-circle"></i>
									</span>
									<p>{teamManagement.userCount}</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle d-flex align-items-center">
									<span className="counter green">3</span>
									<span className="circle green">
										<i className="fas fa-circle"></i>
									</span>
									<p>{teamManagement.branchCount}</p>
								</div>
							</div>
						</div>
					</div>

					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{" "}
										{teamManagement.teamManagement}
									</div>
									<div className="TeamManagement-taps">
										<button
											onClick={() => {
												setActive(1);
											}}
											className={active === 1 ? "active" : ""}
										>
											{teamManagement.users}
										</button>

										<button
											className={active === 2 ? "active" : ""}
											onClick={() => {
												setActive(2);
											}}
										>
											{" "}
											{groups.sections}
										</button>
										<button
											className={active === 3 ? "active" : ""}
											onClick={() => {
												setActive(3);
											}}
										>
											{" "}
											{groups.groups}
										</button>
									</div>
								</div>

								<Filter activePage={active} toggle={toggle} modal={modal} />
								{active === 1 ? (
									<ListUsers toggle={toggle} modal={modal} />
								) : active === 2 ? (
									<ListSections />
								) : (
									<ListGroups />
								)}
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
