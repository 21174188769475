import React from "react";
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import Locale from "translations";

export default function Model(props) {
	const { teamManagement } = Locale;
	const type = props.type;

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				className={props.className}
				size={props.size}
			>
				<ModalHeader className="font-weight-bold" toggle={props.toggle}>
					{props.title}
				</ModalHeader>
				<ModalBody className="py-0">
					<Row className="mx-0">{props.children}</Row>
				</ModalBody>
				{props.hasFooter ? (
					<ModalFooter>
						<Button
							color="primary"
							onClick={props.submit}
							disabled={props.disabled}
						>
							{type === "create"
								? teamManagement.add
								: props.type === "update"
								? teamManagement.edit
								: teamManagement.delete}
						</Button>
						<Button color="secondary" onClick={props.toggle}>
							{teamManagement.cancel}
						</Button>
					</ModalFooter>
				) : null}
			</Modal>
		</>
	);
}

Model.defaultProps = {
	hasFooter: true,
};
