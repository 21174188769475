import React from "react";
import Locale from "translations";

function Footer() {
	const { commons } = Locale;
	return (
		<footer>
			<div className="container-fluid">
				<p className="text-center text-muted p-1 mb-0 border-top">
					{commons.copyRights}
					<a href="#" target="_blank"></a>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
