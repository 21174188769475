import React from "react";
import Locale from "translations";

export default function Pagination(props) {
	const { commons } = Locale;
	return (
		<>
			{props.info?.total > props.items ? (
				<div className="d-flex justify-content-between align-items-center mb-2 mr-2 ml-2">
					<div className="text-body">
						{commons.show} {props.info?.from} {commons.of} {props.info?.total}{" "}
						{commons.entries}
					</div>
					<nav aria-label="Page navigation example">
						<ul className="pagination my-4">
							{props.info?.links?.map((page) => {
								let string;
								string = page.label;
								return (
									<li
										key={`page-${page.label}`}
										className={page.active ? "page-item active" : "page-item"}
										onClick={() => {
											props.goTo(string);
										}}
									>
										<span
											style={{ cursor: "pointer" }}
											dangerouslySetInnerHTML={{ __html: page.label }}
											className={`page-link ${
												page.url === null ? "false" : ""
											}`}
										></span>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
			) : null}
		</>
	);
}
