import uploadImg from "assets/images/labs/cloud-computing.png";
import CheckBox from "components/shared/Chekbox";
import SelectField from "components/shared/SelectField";
import ShowForPermission from "helpers/showForPermission";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function Traveler({
	lab,
	setPolicie,
	policie,
	toggle,
	handleCheck,
	checked,
	setChecked,
	status,
}) {
	const { Labs, commons } = Locale;
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);

	return (
		<>
			<Modal
				size="lg"
				isOpen={modal}
				toggle={toggleModal}
				// className={className}
			>
				<ModalHeader className="d-flex align-items-center justify-content-center ">
					<h5 className=" font-weight-bold">Enter the PCR result</h5>
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="mybooking-input-wrapper">
						<SelectField
							label="PCR Result"
							// options={classifications}
							// onChange={(e) => {
							// 	setMakkahHotelsState({
							// 		...makkahHotelsState,
							// 		classes: { id: e.value, name: e.label },
							// 		country: [],
							// 	});
							// }}
							value="Negative"
							// placeholder={interests.messages.classPlaceholder}
						/>
					</div>
					<div className="input-upload">
						<label for="signup-upload">
							<div className="img-box">
								<div className="shape">
									<img src={uploadImg} alt="IconImg" />
									<div className="btn gold-color-bg white-color font-15 px-3 my-1 text-white">
										File selection
									</div>
								</div>
							</div>
						</label>
						<input type="file" id="signup-upload" />
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModal}
					>
						Back
					</Button>
					<Button className="px-5 w-30" color="success">
						Save
					</Button>{" "}
				</ModalFooter>
			</Modal>
			<tr>
				{/* <th scope="row">{lab.branch_name}</th> */}
				{status === "pending" && (
					<td>
						<CheckBox
							id={lab.id}
							checked={checked.policies.includes(lab.id)}
							onChange={handleCheck}
						/>
					</td>
				)}
				<td>{lab.traveller.traveller_name}</td>
				<td>{lab.traveller.passport_num}</td>
				<td>{lab.traveller.nin}</td>
				<td>{commons[lab.traveller.gender]}</td>
				<td>{lab.traveller.age}</td>
				<td>{lab.request_date}</td>
				{lab.status === "approved" ? (
					<td className="text-success ">
						<i className="fas fa-check px-1"></i>
						{Labs.approved}
					</td>
				) : (
					<td className="text-warning">
						<i class="fas fa-exclamation-triangle  px-1"></i>
						{Labs[lab.status]}
					</td>
				)}
				<td className="text-success ">
					{lab.status === "approved" ? (
						<a
							href={lab.attachment}
							className="btn btn-secondary text-capitalize"
							target="_blank"
						>
							{Labs.print}
						</a>
					) : (
						<ShowForPermission permission="lab:approve-insurance-policies">
							<button
								onClick={() => {
									setChecked({
										...checked,
										policies: [lab.id],
									});
									toggle();
								}}
								className="btn btn-outline-success"
							>
								<i className="fas fa-check px-1"></i>
								{Labs.issue}
							</button>
						</ShowForPermission>
					)}
				</td>

				{/* <td>
					<p>{moment(lab.examination_date).format("DD-MM-YYYY")}</p>
					<p>{moment(lab.examination_date).format("HH-mm-ss")}</p>
				</td> */}
				{/* <td className="align-middle">
					{traveler.analysisResult === true
						? `${Labs.positive}`
						: `${Labs.negative}`}
				</td> */}
				{/* <td>{lab.examing_status}</td>

				<td>
					<p>{moment(lab.analysis_date).format("DD-MM-YYYY")}</p>
					<p>{moment(lab.analysis_date).format("HH-mm-ss")}</p>
				</td> */}
			</tr>
		</>
	);
}
