import Pagination from "components/shared/Pagination";
import React from "react";
import Locale from "translations";
import Traveler from "./Traveler";

function ListTravelers({ travelers, settravelers, meta, filter, setfilter }) {
	const { teamManagement, Labs } = Locale;

	const Alltravelers =
		travelers &&
		travelers.length > 0 &&
		travelers.map((traveler, index) => (
			<Traveler traveler={traveler} key={index} />
		));

	const goTo = async (page) => {
		setfilter({ ...filter, page: page });
	};
	return (
		<>
			<div className="medical-result">
				<div className="TeamManagement-header">
					<div className="TeamManagement-title">{Labs.travelers}</div>
				</div>

				<div className="reservation-table">
					<table className="table table-striped mb-0">
						<thead>
							<tr className="bg-grey text-white">
								<th scope="col">{Labs.pilgrimName}</th>
								<th scope="col">{Labs.passportNumber}</th>
								<th scope="col">{teamManagement.labName}</th>
								<th scope="col">{Labs.nationalId}</th>
								<th scope="col">{Labs.gender}</th>
								<th scope="col">{Labs.age}</th>
								<th scope="col">{Labs.ExaminationDate}</th>
								<th scope="col">{Labs.analysisDate}</th>
								<th scope="col">{Labs.analysisResults}</th>
							</tr>
						</thead>
						<tbody>
							{Alltravelers && Alltravelers.length > 0 ? (
								Alltravelers
							) : (
								<tr>
									<td colSpan="11">
										<div className="product-build__product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{Labs.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				{/* Modal for view PCR result */}

				<Pagination info={meta} goTo={goTo} items={10} />
			</div>
		</>
	);
}

export default ListTravelers;
