import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./style.scss";
function Layout({ children }) {
	return (
		<>
			<Header />
			<main className="main-color">{children}</main>
			<Footer />
		</>
	);
}

export default Layout;
