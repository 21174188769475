import React from "react";
import Locale from "translations";

export default function Group({ user }) {
	const { teamManagement } = Locale;

	return (
		<>
			<tr>
				<td>{user.name}</td>
				<th>{user.email}</th>
				<td>
					{user.status === "active" ? (
						<i className="far fa-check-circle btn fa-2x text-success"></i>
					) : (
						<i className="far fa-times-circle btn fa-2x text-danger"></i>
					)}
				</td>
			</tr>
		</>
	);
}
