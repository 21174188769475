import { LabsProvider } from "context/global";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Sentry.init({
//   dsn: "https://611525412baf48be8952f1ce130e0b11@o457743.ingest.sentry.io/5670296",
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
	<React.StrictMode>
		<LabsProvider>
			{/* <Sentry.ErrorBoundary fallback={() => <p>"An error has occurred"</p>}> */}
			<App />
			{/* </Sentry.ErrorBoundary> */}
		</LabsProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
