import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
export default function Company({ company }) {
	const { Labs } = Locale;
	const [status, setStatus] = useState(
		company.status === "active" ? true : false
	);
	const locale = localStorage.getItem("currentLocale") || "en";

	const toggleButton = async () => {
		// const res = await changeStatus(company.id);
		// if (res === 200) {
		// 	console.log("fetchStatus", res);
		// }
		setStatus(!status);
	};
	// console.log({ company });
	return (
		<>
			<tr>
				<td>{company.name}</td>
				<td>{company.email}</td>
				<td>{company.country.names[locale]}</td>
				<td>{company.city.names[locale]}</td>
				<td>{company.phone_number}</td>
				<td>{company.website}</td>
				<td>
					<i
						className={`far fa-2x text-success ${
							status === true
								? "fa-check-circle text-success"
								: "text-danger fa-times-circle"
						}`}
					></i>
				</td>
				<td>{moment(company.created_at).format("DD-MM-YYY")}</td>

				<td>
					<Link
						to={`/admin/company-info/${company.id}`}
						className="link-company text-gray"
					>
						<i className="far fa-edit"></i>
						{Labs.edit}
					</Link>
				</td>
				<td className="align-middle pointer">
					<Link to="/admin/company-user" className="link-company  text-success">
						<i className="fa fa-users"></i>
						{Labs.users}
					</Link>
				</td>

				<td className="align-middle pointer" onClick={toggleButton}>
					<a
						className={`link-company pointer ${
							status !== true ? "text-danger" : "text-success"
						}`}
					>
						<i
							className={`fas fa-fw ${status !== true ? "fa-stop" : "fa-play"}`}
						></i>
						{status !== true ? `${Labs.stop}` : `${Labs.play}`}
					</a>
				</td>
			</tr>
		</>
	);
}
