import React from "react";
import Locale from "translations";

export default function Activity() {
	const { statistics } = Locale;

	let activityRecords = [
		{
			id: 1,
			time: "11:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 2,
			time: "15:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 3,
			time: "18:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 4,
			date: "Mar 20, 2021",
			time: "22:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 5,
			time: "10:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 6,
			time: "14:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 7,
			time: "13:58",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
		{
			id: 8,
			time: "22:10",
			city: "Cairo Egypt",
			type: "Chrome | Mac Os X 10.15.3 | 41.36.21",
		},
	];

	const Activities = activityRecords.map((activity) => (
		<div className="main pt-3 d-flex" key={activity.id}>
			<div className="time font-weight-bold">{activity.time}</div>
			<div className="px-2">
				<span className="tes">
					<i className="far fa-circle bg-white text-primary"></i>
				</span>
			</div>
			<div>
				<h2 className="text-uppercase text-primary font-weight-bold">
					{statistics.signIn}
				</h2>
				<p>{activity.city}</p>
				<p>{activity.type}</p>
				{activity.date ? (
					<div className="date">
						<p className="mb-0">{activity.date}</p>
					</div>
				) : null}
			</div>
		</div>
	));

	return (
		<>
			<div className="p-2 activity bg-white shadow-sm rounded">
				<div className="title">
					<h2 className="font-weight-bold text-subtitle">
						{statistics.myActivity}
					</h2>
					<p className="text">{statistics.activityText}</p>
				</div>
				<div className="date">
					<p>Mar 16, 2021</p>
				</div>

				{Activities}
			</div>
		</>
	);
}
