import React from "react";
import User from "./User";

export default function ListUsers() {
	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">اسم المستخدم</th>
									<th scope="col">البريد الالكتروني</th>
									<th scope="col">اسم المجموعة</th>
									<th scope="col">الحالة</th>
									<th colSpan="4" scope="col">
										العمليات
									</th>
								</tr>
							</thead>
							<tbody>
								<User />
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
