import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Locale from "translations";
export default function Traveler({ traveler, lab }) {
	const { Labs } = Locale;
	const { commons } = Locale;
	let currentLocale = localStorage.getItem("currentLocale") ?? "en";
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);
	const locale = localStorage.getItem("currentLocale") || "en";
	return (
		<>
			<Modal
				isOpen={modal}
				toggle={toggleModal}
				// className={className}
			>
				<ModalHeader className="d-flex align-items-center justify-content-center border-0 pb-0">
					<h5 className=" font-weight-bold">Modification Adjustments</h5>
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="modification-modal">
						<span className="text-success">Checked</span>
						<span className="px-2">By The Employee: Ahmed Mohamed</span>
						<p className="text-gray text-body">
							02-02-2021 <span>12:30pm</span>
						</p>
					</div>
					<div className="modification-modal">
						<span className="text-success">Checked</span>
						<span className="px-2">By The Employee: Ahmed Mohamed</span>
						<p className="text-gray text-body">
							02-02-2021 <span>12:30pm</span>
						</p>
					</div>
					<div className="modification-modal">
						<span className="text-success">Checked</span>
						<span className="px-2">By The Employee: Ahmed Mohamed</span>
						<p className="text-gray text-body">
							02-02-2021 <span>12:30pm</span>
						</p>
					</div>
				</ModalBody>
				<ModalFooter className="border-0 d-flex justify-content-center pt-0">
					<Button
						className="bg-secondary  border-0 px-5 py-2"
						onClick={toggleModal}
					>
						Back
					</Button>
				</ModalFooter>
			</Modal>
			<tr>
				<th scope="row">
					{lab.company_name != null && (
						<h2>
							{lab.company_name[locale]
								? lab.company_name[locale]
								: locale === "ar"
								? lab.company_name.en
								: locale === "en"
								? lab.company_name.ar
								: "invalid name"}
						</h2>
					)}
					{lab.company_name === null && <h2>invalid name</h2>}
					<span className="text-muted">{lab.company_code}</span>
				</th>
				<th scope="row">{lab.lab.name}</th>
				<td>{lab.traveller.traveller_name}</td>
				<td>{lab.traveller.passport_num}</td>
				<td>{lab.traveller.nin}</td>
				<td>
					{lab.traveller.gender === "male" ? commons.male : commons.female}
				</td>
				<td>{lab.traveller.age}</td>
				{/* <td>{moment(lab.departure_date).format("DD-MM-YYYY")}</td> */}
				<td>
					<p>{lab.request_date}</p>
				</td>
				{lab.status === "pending" ? (
					<>
						<td className="text-warning text-left">
							<i className="fas fa-times px-1"></i>
							{commons.pending}
						</td>
						<td className="text-left"></td>
					</>
				) : (
					<>
						<td className="text-left">{commons.approved}</td>
						<td className="text-left">
							<a
								href={lab.attachment}
								className="btn btn-secondary text-capitalize"
								target="_blank"
							>
								{Labs.print}
							</a>
						</td>
					</>
				)}
				{/* <td className="text-success text-center">
					<i className="fas fa-check px-1"></i>
					{Labs.checked}
				</td> */}

				{/* <td className="align-middle">
					{traveler.analysisResult === true
						? `${Labs.positive}`
						: `${Labs.negative}`}
				</td> */}
			</tr>
		</>
	);
}
