import PasswordField from "components/shared/PasswordField";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
	changePassWordLabApi,
	changeStatusLabApi,
	filterLabs,
} from "services/lab";
import Locale from "translations";
import Lab from "./Lab";

export default function List({
	setLabs,
	labs,
	meta,
	setMeta,
	filter,
	setfilter,
}) {
	const { Labs, teamManagement, users, commons } = Locale;
	//const [labs, setLabs] = useState([]);
	const [errors, setErrors] = useState({});
	const [changePassword, setChangePassword] = useState({});
	const [userid, setUserId] = useState(0);
	const [modalPassword, setModalPassword] = useState(false);
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const changeStatusLab = async (id, status) => {
		const res = await changeStatusLabApi(id, status);
		if (res.status === 200) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});

			const labs = await filterLabs({
				...filter,
				status: filter.status?.value,
			});
			if (labs.data.data.length > 0) {
				setLabs(labs.data.data);
				let meta = labs.data.meta;
				setMeta(meta);
			} else if ((labs.data.data.length == 0) & (filter.page > 1)) {
				setfilter({ ...filter, page: filter.page - 1 });
			}
		}
	};

	const togglePassword = (e) => {
		setChangePassword({});
		setUserId(e);
		setModalPassword(!modalPassword);
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "password",
					value: changePassword.password,
				},
				{
					required: true,
					password: true,
					min: 8,
				}
			),
			...validate(
				{
					name: "password_confirmation",
					value: changePassword.password_confirmation,
				},
				{
					required: true,
					confirm:
						changePassword.password_confirmation !== changePassword?.password,
				}
			),
		});
	};
	const handlePassword = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		const changePasswordUser = async () => {
			if (isFormValid(errors)) {
				const res = await changePassWordLabApi(userid, changePassword);

				if (res.status == 201) {
					togglePassword(0);
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};

		changePasswordUser();
	}, [isErrorLoaded]);

	// console.log("---", labs);

	const AllLabs =
		labs &&
		labs.length > 0 &&
		labs.map((lab, index) => (
			<Lab
				key={"lab" + index}
				lab={lab}
				index={index}
				changeStatusLab={changeStatusLab}
				togglePassword={togglePassword}
				// removeItem={removeItem}
			/>
		));

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">{Labs.labName}</th>
									<th scope="col">{Labs.country}</th>
									<th scope="col">{Labs.city}</th>
									{/* <th scope="col">{Labs.branches}</th> */}
									<th scope="col">{teamManagement.phoneNumber}</th>
									<th scope="col">{Labs.email}</th>
									<th scope="col">{Labs.status}</th>
									<th colSpan="5" scope="col" className="text-center">
										{Labs.processes}
									</th>
								</tr>
							</thead>
							<tbody>
								{AllLabs && AllLabs.length > 0 ? (
									AllLabs
								) : (
									<tr>
										<td colSpan="11">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{Labs.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

				<Modal isOpen={modalPassword} toggle={togglePassword}>
					<ModalHeader toggle={togglePassword}>
						{users.changePassword}
					</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-md-12">
								<PasswordField
									type="text"
									label={teamManagement.password}
									placeholder={teamManagement.password}
									name="password"
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												password: true,
												min: 8,
											}),
										});
										setChangePassword({
											...changePassword,
											password: e.target.value,
										});
									}}
									min={8}
									color={
										errors?.password?.required ||
										errors?.password?.password ||
										errors?.password?.min
											? "danger"
											: ""
									}
									errors={errors?.password}
									value={changePassword.password}
								/>
							</div>
							<div className="col-md-12">
								<PasswordField
									type="text"
									label={teamManagement.password_confirmation}
									placeholder={teamManagement.password_confirmation}
									name="password_confirmation"
									onChange={(e) => {
										setChangePassword({
											...changePassword,
											password_confirmation: e.target.value,
										});
										setErrors({
											...errors,
											...validate(e.target, {
												required: true,
												confirm: e.target.value !== changePassword?.password,
											}),
										});
									}}
									color={
										errors?.password_confirmation?.required ||
										errors?.password_confirmation?.confirm
											? "danger"
											: ""
									}
									errors={errors?.password_confirmation}
									value={changePassword.password_confirmation}
								/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={handlePassword}>
							{commons.save}
						</Button>{" "}
						<Button color="secondary" onClick={togglePassword}>
							{commons.back}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</>
	);
}
