// import sitting from "../assets/images/setting.png";
// import { fetchImages } from "services/auth";
// import { fetchUserProfile } from "services/profile";
import PasswordField from "components/shared/PasswordField";
import { useLabsDispatch } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Link, useHistory } from "react-router-dom";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalFooter,
	UncontrolledDropdown,
} from "reactstrap";
import { changeCompanyPassword } from "services/lab";
import Locale from "translations";

export default function UserSettings({ changePassword }) {
	const { Labs, teamManagement, users } = Locale;
	const history = useHistory();
	const dispatch = useLabsDispatch();
	const [isAdmin] = useState(true);
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [changePass, setChangePass] = useState({});

	// useEffect(() => {
	// 	if (isAuth) {
	// 		async function fetchData() {
	// 			const user = await fetchUserProfile();
	// 			if (user.avatar) {
	// 				const fetchImage = await fetchImages(user.avatar);
	// 				if (fetchImage.status === 200) {
	// 					setAvatar(fetchImage);
	// 				}
	// 			}
	// 		}
	// 		fetchData();
	// 	}
	// }, [isAuth]);

	useEffect(() => {
		const changePasswordCompany = async () => {
			if (isFormValid(errors)) {
				const res = await changeCompanyPassword(changePass);
				if (res.status == 200) {
					toggle();
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		};
		changePasswordCompany();
	}, [isErrorLoaded]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "password", value: changePass.password },
				{ required: true, password: true, min: 8 }
			),
			...validate(
				{
					name: "password_confirmation",
					value: changePass.password_confirmation,
				},
				{
					required: true,
					confirm: changePass.password_confirmation !== changePass?.password,
				}
			),
		});
	};

	const sumbit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	return (
		<UncontrolledDropdown className="setting-dropdown">
			<DropdownToggle className="btn-light p-0">
				<div className="text-gray">
					<i className="fas fa-cog fa-lg mx-1"></i>
					<span className="text-headline">{Labs.setting}</span>
				</div>
			</DropdownToggle>

			<DropdownMenu right>
				{/*  satart lab data */}
				{/*
				<Link to="/user-profile">
					<DropdownItem className="text-gray">
						{Labs.labData}
						<i className="fas fa-user-circle px-2 fa-fw"></i>
					</DropdownItem>
				</Link>
				*/}
				{/*  end lab data */}

				{isAdmin && (
					<Link onClick={() => changePassword}>
						<DropdownItem className="text-gray" onClick={toggle}>
							{Labs.changePassword}
							<i className="fas fa-unlock-alt px-2 fa-fw"></i>
						</DropdownItem>
					</Link>
				)}

				<div className="container">
					<Modal isOpen={modal} toggle={toggle}>
						<p className="text-center my-3 font-weight-bold h3">
							{teamManagement.changePassword}
						</p>

						<ModalBody>
							<PasswordField
								type="text"
								label={teamManagement.OldPassword}
								placeholder={teamManagement.OldPassword}
								name="old_password"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
										}),
									});
									setChangePass({
										...changePass,
										current_password: e.target.value,
									});
								}}
								value={changePass.current_password}
							/>

							<PasswordField
								type="text"
								label={teamManagement.NewPassword}
								placeholder={users.newPasswordPlaceholder}
								name="password"
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											password: true,
											min: 8,
										}),
									});
									setChangePass({
										...changePass,
										password: e.target.value,
									});
								}}
								value={changePass.password}
								min={8}
								color={
									errors?.password?.required ||
									errors?.password?.password ||
									errors?.password?.min
										? "danger"
										: ""
								}
								errors={errors?.password}
							/>

							<PasswordField
								type="text"
								label={teamManagement.password_confirmation}
								placeholder={teamManagement.password_confirmation}
								name="password_confirmation"
								onChange={(e) => {
									setChangePass({
										...changePass,
										password_confirmation: e.target.value,
									});

									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											confirm: e.target.value !== changePass?.password,
										}),
									});
								}}
								value={changePass.password_confirmation}
								color={
									errors?.password_confirmation?.required ||
									errors?.password_confirmation?.confirm
										? "danger"
										: ""
								}
								errors={errors?.password_confirmation}
							/>
						</ModalBody>

						<ModalFooter className="flex-nowrap align-items-center ">
							<button
								className=" btn-success w-75 h6 rounded p-2"
								onClick={sumbit}
							>
								{Labs.changePassword}
							</button>

							<button
								className="btn-secondary w-25  h6 rounded p-2 border-0"
								onClick={toggle}
							>
								{teamManagement.cancel}
							</button>
						</ModalFooter>
					</Modal>
				</div>

				<DropdownItem
					className="text-gray"
					onClick={() => {
						dispatch({ type: "logout" });
						history.push("/auth/login");
					}}
				>
					{Labs.logout}
					<i className="fas fa-sign-out-alt px-2 fa-fw"></i>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
