import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { formatPostUser } from "data/lab";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { editUser } from "services/lab";
import { fetchCountries } from "services/lookups";
import Locale from "translations";
export default function AddUser({ toggle, modal }) {
	const { teamManagement, users } = Locale;
	// const [modal, setModal] = useState(false);
	// const toggle = () => setModal(!modal);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [user, setUser] = useState({
		userName: "",
		email: "",
		branch: "",
		section: "",
		group: "",
		country: [],
	});
	console.log("User", user);
	const [countries, setCountries] = useState([]);
	const { id } = useParams();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	console.log("ID ===>", id);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "userName", value: user.userName },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: user.email },
				{ required: true, email: true }
			),
			...validate({ name: "branch", value: user.branch }, { required: true }),
			...validate({ name: "section", value: user.section }, { required: true }),
			...validate({ name: "group", value: user.group }, { required: true }),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setUser({ ...user, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setUser({ ...user, city: "", [name]: value });
			} else setUser({ ...user, [name]: value });
		}
	};

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((e) => ({
				value: e.id,
				label: e.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	useEffect(async () => {
		// debugger;
		if (isFormValid(errors)) {
			if (id) {
				const data = formatPostUser(user);
				const res = await editUser(data, id);

				if (res.status === 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		}
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
		alert(`
			User Name => ${user.username}
			Email => ${user.email}
			Branch => ${user.branch?.label}
			Section => ${user.section?.label}
			Group => ${user.group?.label}
		`);
	};

	return (
		<>
			<button onClick={toggle}>{teamManagement.addUser}</button>

			<Modal className={`add-model`} isOpen={modal} toggle={toggle}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{teamManagement.addUser}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<TextField
							type="text"
							label={users.userName}
							placeholder={users.userName}
							value={user.userName}
							name="userName"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.userName?.required ? "danger" : ""}
							errors={errors?.userName}
							min={3}
						/>
						<TextField
							type="text"
							label={teamManagement.email}
							placeholder={teamManagement.email}
							value={user.email}
							name="email"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.email?.required ? "danger" : ""}
							errors={errors?.email}
						/>

						<SelectField
							type="text"
							name="branch"
							label={teamManagement.branch}
							placeholder={teamManagement.branchPlaceholder}
							errors={errors?.branch}
							color={errors?.branch?.required ? "danger" : ""}
							options={countries}
							onChange={(e) => {
								// debugger;
								handleChange({ name: "branch", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "branch", value: e },
										{
											required: true,
										}
									),
								});
								// setUser({
								// 	...user,
								// 	branch: e,
								// });
							}}
							value={user.branch}
						/>
						<SelectField
							type="text"
							name="section"
							label={teamManagement.section}
							placeholder={teamManagement.sectionPlaceholder}
							errors={errors?.section}
							color={errors?.section?.required ? "danger" : ""}
							options={countries}
							onChange={(e) => {
								handleChange({ name: "section", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "section", value: e },
										{
											required: true,
										}
									),
								});
							}}
							value={user.section}
						/>
						<SelectField
							type="text"
							name="group"
							label={teamManagement.group}
							placeholder={teamManagement.groupPlaceholder}
							errors={errors?.group}
							color={errors?.group?.required ? "danger" : ""}
							options={countries}
							onChange={(e) => {
								handleChange({ name: "group", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "group", value: e },
										{
											required: true,
										}
									),
								});
							}}
							value={user.group}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={submit}>
						{teamManagement.addUser}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
