import moment from "moment";
import React, { useEffect, useState } from "react";
import { travelerPCRLabs } from "services/lab";
import Locale from "translations";
import Filter from "./Filter";
import ListTravelers from "./ListTravelers";
function PcrReservations() {
	const { teamManagement, Labs } = Locale;
	const [travelers, settravelers] = useState({});
	const [meta, setmeta] = useState();
	const [filter, setfilter] = useState({
		national_id: "",
		passport_number: "",
		status: null,
		examination_date: "",
		analysis_date: "",
	});

	/* get travelers list */
	useEffect(() => {
		async function fetchDataFilter() {
			const Pcr = await travelerPCRLabs({
				...filter,
				examination_date: filter.examination_date
					? moment(filter.examination_date).format("DD-MM-YYYY")
					: "",
				analysis_date: filter.analysis_date
					? moment(filter.analysis_date).format("DD-MM-YYYY")
					: "",
				status: filter.status?.value,
			});
			settravelers(Pcr.data.data);
			setmeta(Pcr.data.meta);
		}

		const filterHandler = setTimeout(() => {
			fetchDataFilter();
		}, 500);

		return () => clearTimeout(filterHandler);
	}, [filter]);

	return (
		<>
			<section className="main-color medical-section">
				<div className="container-fluid">
					<Filter filter={filter} setfilter={setfilter} />
					<ListTravelers
						travelers={travelers}
						settravelers={settravelers}
						meta={meta}
						filter={filter}
						setfilter={setfilter}
					/>
				</div>
			</section>
		</>
	);
}

export default PcrReservations;
