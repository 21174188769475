import { formatGetBranch } from "data/lab";
import React, { useEffect, useState } from "react";
import { branchesList, getBranch } from "services/lab";
import FilterBranch from "./BranchesList/FilterBranch";
import ListBranches from "./BranchesList/ListBranches";

export default function BranchesManagement() {
	const [branch, setBranch] = useState({
		id: "",
		branchName: "",
		email: "",
		country: "",
		city: "",
		area: "",
		address: "",
		phone: "",
	});
	console.log("BRANCH", branch);

	const [modal, setModal] = useState(false);
	const toggle = () => {
		setModal(!modal);
		if (!modal) {
			setBranch({
				id: "",
				branchName: "",
				email: "",
				country: "",
				city: "",
				area: "",
				address: "",
				phone: "",
			});
		}
	};

	async function getBranchesApi(id) {
		if (id) {
			const res = await getBranch(id);
			if (res.status === 200) {
				const formatted = formatGetBranch(res.data.data);
				setBranch(formatted);
			}
		}
	}

	async function fetchData() {
		const branches = await branchesList();
		setBranchList(branches.data.data.data);
	}

	const [branchList, setBranchList] = useState([]);
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<FilterBranch
				modal={modal}
				toggle={toggle}
				branch={branch}
				setBranch={setBranch}
				setBranchList={setBranchList}
				fetchData={fetchData}
			/>
			<ListBranches
				modal={modal}
				toggle={toggle}
				branch={branch}
				setBranch={setBranch}
				getBranchesApi={getBranchesApi}
				branchList={branchList}
				setBranchList={setBranchList}
				fetchData={fetchData}
			/>
		</>
	);
}
