import React from "react";
import Locale from "translations";
import Group from "./Group";
export default function GroupTable({ users }) {
	const { companies } = Locale;
	return (
		<>
			<div className="content-team">
				<div className="collapse show">
					<div className="card card-body p-0 mt-3">
						<div className="table-responsive "></div>
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th>{companies.userName}</th>

									<th>{companies.useGroup}</th>
									<th>{companies.userEmail} </th>
								</tr>
							</thead>
							<tbody>
								{users && users.length > 0
									? users.map((res) => <Group user={res} />)
									: ""}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
