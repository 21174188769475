import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";

export default function Filter({ filter, setfilter, status }) {
	const { Labs, teamManagement, commons } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";

	/* 
	const [filterState, setFilterState] = useState({
		nationalId: "",
		passportNumber: "",
		statusCheck: "",
		examinationDate: undefined,
	}); */

	/* 	const searchBtn = () => {
		alert(`
			National Id => ${filterState.nationalId}
			Passport Number => ${filterState.nationalId}
			Status Check => ${filterState.statusCheck?.label}
			Examination Date => ${filterState.examinationDate}
		`);
	}; */
	/* 
	const [countries, setCountries] = useState([]);

	const [cities, setCities] = useState([]);
 */

	const genders = [
		{ value: "male", label: commons.male },
		{ value: "female", label: commons.female },
	];

	/* 	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCities(filter?.country?.value);
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCities(format);
		}
		fetchLookups();
	}, [filter.country]); */

	return (
		<>
			<div className="row align-items-center m-0">
				<div className="col-md-2">
					<TextField
						label={Labs.companyName}
						onChange={(e) => {
							setfilter({ ...filter, company_name: e.target.value, page: 1 });
						}}
						value={filter.company_name}
						placeholder={Labs.companyNamePlaceholder}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						label={Labs.licenseNumber}
						onChange={(e) => {
							setfilter({ ...filter, license_number: e.target.value, page: 1 });
						}}
						value={filter.license_number}
						placeholder={Labs.licenseNumberPlaceHolder}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						label={teamManagement.labName}
						onChange={(e) => {
							setfilter({ ...filter, lab_name: e.target.value, page: 1 });
						}}
						value={filter.lab_name}
						placeholder={teamManagement.labNamePlaceholder}
					/>
				</div>

				{/* 				<div className="col-md-2">
					<SelectField
						label={teamManagement.governorate}
						onChange={(e) => {
							setfilter({ ...filter, country: e });
						}}
						value={filter.country}
						options={countries}
						placeholder={teamManagement.governoratePlaceholder}
					/>
				</div>

				<div className="col-md-2">
					<SelectField
						label={teamManagement.city}
						onChange={(e) => {
							setfilter({ ...filter, city: e });
						}}
						value={filter.city}
						options={cities}
						placeholder={teamManagement.city}
					/>
				</div> */}

				<div className="col-md-1">
					<TextField
						label={Labs.passportNumber}
						onChange={(e) => {
							setfilter({
								...filter,
								passport_number: e.target.value,
								page: 1,
							});
						}}
						value={filter.passport_number}
						placeholder={Labs.passportNumberPlaceHolder}
					/>
				</div>

				<div className="col-md-1">
					<TextField
						label={Labs.nationalId}
						onChange={(e) => {
							setfilter({ ...filter, national_id: e.target.value, page: 1 });
						}}
						value={filter.national_id}
						placeholder={Labs.nationalIdPlaceholder}
					/>
				</div>

				<div className="col-md-1">
					<SelectField
						value={filter.gender}
						label={Labs.gender}
						placeholder={Labs.genderPlaceholder}
						name="gender"
						options={genders}
						onChange={(e) => {
							setfilter({ ...filter, gender: e, page: 1 });
						}}
					/>
				</div>

				{/* <div className="col-md-2">
					<SelectField
					label={teamManagement.branch}
					// options={}
						onChange={(e) => {
							setFilterState({
								...filterState,
								statusCheck: e,
							});
						}}
						value={filterState.statusCheck}
						placeholder={teamManagement.branchPlaceholder}
						/>
					</div> */}
				{/* <div className="col-md-2">
					<TextField
					type="text"
					label={Labs.nationalId}
					placeholder={Labs.nationalIdPlaceholder}
					value={filterState.nationalId}
					name="nationalId"
					onChange={(e) => {
						setfilter({ ...filter, city: e });
					}}
					/>
					</div>
					<div className="col-md-2">
					<TextField
					type="text"
					label={Labs.passportNumber}
					placeholder={Labs.passportNumberPlaceHolder}
					value={filterState.passportNumber}
					name="passportNumber"
					onChange={(e) => {
						setFilterState({
								...filterState,
								passportNumber: e.target.value
								.replace(/[^\d]/g, "")
								.replace(/[^\w\s]/gi, "")
								.replace(/^0/, ""),
							});
						}}
						/>
				</div>
			*/}
				{/* <div className="col-md-2">
					<SelectField
					label={Labs.statusCheck}
					// options={}
					onChange={(e) => {
						setFilterState({
							...filterState,
								statusCheck: e,
							});
						}}
						value={filterState.statusCheck}
						placeholder={Labs.statusCheckPlaceholder}
						/>
					</div> */}

				<div className="col-md-2">
					<DatePickerField
						label={status === "pending" ? Labs.applicationDate : Labs.issueDate}
						placeholder="YY-MM-DD"
						date={filter.request_date}
						onDateChange={(e) => {
							// moment(e._d).format("DD-MM-YYYY")
							setfilter({
								...filter,
								request_date: e,
								page: 1,
							});
						}}
						isOutsideRange={(day) => false}
					/>
				</div>

				{/* 				<div className="col-md-2">
					<div className="mybooking-input-wrapper px-1 w-100">
					<button
					type="button"
					className="btn btn-primary submit-btn w-100"
					onClick={searchBtn}
					>
					{Labs.search}
					</button>
					</div>
				</div> */}
				<div
					className=" border p-2 rounded bg-white"
					role="button"
					onClick={() => {
						setfilter({
							lab_name: "",
							national_id: "",
							passport_number: "",
							company_name: "",
							request_date: "",
							license_number: "",
							gender: null,
							page: 1,
						});
					}}
				>
					<span>{commons.reset}</span>
					<i class="fas fa-sync-alt m-2"></i>
				</div>
			</div>
		</>
	);
}
