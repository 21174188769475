import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useState } from "react";
import Locale from "translations";

export default function Filter() {
	const { Labs } = Locale;

	const [filterState, setFilterState] = useState({
		nationalId: "",
		passportNumber: "",
		statusCheck: "",
		examinationDate: undefined,
	});

	const searchBtn = () => {
		alert(`
			National Id => ${filterState.nationalId}
			Passport Number => ${filterState.nationalId}
			Status Check => ${filterState.statusCheck?.label}
			Examination Date => ${filterState.examinationDate}
		`);
	};

	return (
		<>
			<div className="row align-items-center m-0">
				<div className="col-md">
					<TextField
						type="text"
						label={Labs.nationalId}
						placeholder={Labs.nationalIdPlaceholder}
						value={filterState.nationalId}
						name="nationalId"
						onChange={(e) => {
							setFilterState({
								...filterState,
								nationalId: e.target.value
									.replace(/[^\d]/g, "")
									.replace(/[^\w\s]/gi, "")
									.replace(/^0/, ""),
							});
						}}
					/>
				</div>
				<div className="col-md">
					<TextField
						type="text"
						label={Labs.passportNumber}
						placeholder={Labs.passportNumberPlaceHolder}
						value={filterState.passportNumber}
						name="passportNumber"
						onChange={(e) => {
							setFilterState({
								...filterState,
								passportNumber: e.target.value
									.replace(/[^\d]/g, "")
									.replace(/[^\w\s]/gi, "")
									.replace(/^0/, ""),
							});
						}}
					/>
				</div>

				<div className="col-md">
					<SelectField
						label={Labs.statusCheck}
						// options={}
						onChange={(e) => {
							setFilterState({
								...filterState,
								statusCheck: e,
							});
						}}
						value={filterState.statusCheck}
						placeholder={Labs.statusCheckPlaceholder}
					/>
				</div>

				<div className="col-md">
					<DatePickerField
						label={Labs.examinationDate}
						placeholder="YY-MM-DD"
						placeholder="YY-MM-DD"
						date={filterState.examinationDate}
						onChangeDate={(filterDate) => {
							setFilterState({
								...filterState,
								examinationDate: filterDate,
							});
						}}
					/>
				</div>

				<div className="col-md">
					<div className="mybooking-input-wrapper px-1 w-100">
						<button
							type="button"
							className="btn btn-primary submit-btn w-100"
							onClick={searchBtn}
						>
							{Labs.search}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
