import React, { useState } from "react";
import Locale from "translations";
import ChangePassword from "./ChangePassword";
import EditUser from "./EditUser";
import User from "./User";
export default function ListUsers() {
	const { teamManagement, Labs, companies } = Locale;
	const [modalEdit, setModalEdit] = useState(false);
	const toggleModelEdit = () => setModalEdit(!modalEdit);
	const [modalChangePassword, setModalChangePassword] = useState(false);
	const toggleChangePassword = () =>
		setModalChangePassword(!modalChangePassword);
	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">{companies.userName}</th>
									<th scope="col">{companies.email}</th>
									<th scope="col">{teamManagement.groupName}</th>
									<th scope="col">{teamManagement.status}</th>
									<th colSpan="4" scope="col">
										{Labs.processes}
									</th>
								</tr>
							</thead>
							<tbody>
								<EditUser
									modalEdit={modalEdit}
									toggleModelEdit={toggleModelEdit}
								/>
								<ChangePassword
									modalChangePassword={modalChangePassword}
									toggleChangePassword={toggleChangePassword}
								/>
								<User
									toggleModelEdit={toggleModelEdit}
									toggleChangePassword={toggleChangePassword}
								/>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
