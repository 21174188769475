import React from "react";

export default function User() {
	return (
		<>
			<tr>
				<td>ahmed</td>
				<td>email@email.com</td>
				<td>مجموعة 1</td>
				<td>
					<i className="far fa-check-circle btn fa-2x text-success"></i>
				</td>

				<td className="w-10">
					<button type="button" className="btn btn-link text-gray">
						<i className="far fa-edit"></i> تعديل
					</button>
				</td>
				<td className="w-10">
					<button type="button" className="btn btn-link text-danger">
						<i className="fas fa-stop"></i>
						إيقاف
					</button>
				</td>
				<td className="w-10">
					<button type="button" className="text-gray btn btn-link ">
						<i className="fas fa-unlock-alt"></i> تغيير كلمة المرور
					</button>
				</td>
				<td className="w-10">
					<button type="button" className="btn btn-danger">
						حذف
					</button>
				</td>
			</tr>
		</>
	);
}
