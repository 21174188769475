import React from "react";
import Locale from "translations";
function User({ user, togglePassword, changeStatuslabUser, labId }) {
	const { Labs } = Locale;

	return (
		<>
			<tr>
				<td>{user.name} </td>
				<td>{user.email} </td>
				<td>
					{user.status === "active" ? (
						<i className="far fa-check-circle fa-2x text-success fa-fw"></i>
					) : (
						<i className="far fa-times-circle fa-2x text-danger fa-fw"></i>
					)}
				</td>

				<td>
					<button
						onClick={() => {
							togglePassword(user.id);
						}}
						type="button"
						className="text-gray btn btn-link "
					>
						<i className="fas fa-unlock-alt"></i> {Labs.changePassword}
					</button>
				</td>

				<td>
					{user.status == "active" ? (
						<button
							type="button"
							className="font14 btn-default border-0 font-weight-bold bg-transparent text-danger"
							onClick={() => changeStatuslabUser(user.id, "deactivate")}
						>
							<i className="fas fa-stop fa-fw"></i> {Labs.deactivate}
						</button>
					) : (
						<button
							type="button"
							className="font14 btn-default border-0 font-weight-bold bg-transparent text-success"
							onClick={() => changeStatuslabUser(user.id, "activate")}
						>
							<i className="fas fa-play"></i> {Labs.activate}
						</button>
					)}
				</td>
			</tr>
		</>
	);
}

export default User;
