import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllHotelsPcr } from "services/lab";
import { fetchAreas } from "services/lookups";
import Locale from "translations";

export default function Filter({ filterData, setFilterData }) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { teamManagement, Labs, commons } = Locale;
	const [area, setArea] = useState([]);
	const [hotels, setHotels] = useState([]);
	const [areas, setAreas] = useState({});

	const { city_id } = useParams();

	/*  fetch areawa */

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchAreas(city_id);
			const formatted = res.map((area) => ({
				value: area.id,
				label: area.names[locale],
			}));
			setArea(formatted);
		}
		fetchLookups();
	}, []);

	/*  fetch hotels */
	useEffect(() => {
		async function fetchLookups() {
			const res = await getAllHotelsPcr(areas?.selected?.value);

			if (res?.data?.data.lenght != 0) {
				const formatted = res.data.data.map((hotel) => ({
					value: hotel.id,
					label: hotel.name,
				}));
				setHotels(formatted);
			}
		}
		fetchLookups();
	}, [areas?.selected]);
	console.log(setFilterData);
	return (
		<>
			<div className="row align-items-center m-0">
				<div className="col-md-2">
					<SelectField
						label={teamManagement.region}
						value={areas?.selected}
						placeholder={teamManagement.regionPlaceholder}
						name="status"
						options={area}
						onChange={(e) => {
							setAreas({
								...areas,
								selected: e,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<SelectField
						label={teamManagement.hotel}
						value={filterData.hotel_name}
						placeholder={teamManagement.hotelPlaceholder}
						name="status"
						options={hotels}
						onChange={(e) => {
							console.log(e);
							setFilterData({
								...filterData,
								hotel_id: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						type="text"
						placeholder={Labs.passportNumberPlaceHolder}
						label={Labs.passportNumber}
						value={filterData.passport_number}
						onChange={(e) => {
							setFilterData({
								...filterData,
								passport_number: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<TextField
						type="text"
						placeholder={teamManagement.travelerPlaceholder}
						label={teamManagement.traveler}
						value={filterData.name}
						onChange={(e) => {
							setFilterData({
								...filterData,
								name: e.target.value,
							});
						}}
					/>
				</div>

				<div className="col-md-2">
					<DatePickerField
						label={teamManagement.dateInOut}
						placeholder="YY-MM-DD"
						date={filterData.date}
						onDateChange={(e) => {
							// moment(e._d).format("DD-MM-YYYY")
							setFilterData({
								...filterData,
								date: e,
								page: 1,
							});
						}}
						isOutsideRange={(day) => false}
					/>
				</div>

				{/* 				<div className="col-md-1">
						<button className=" btn-dark w-100 p-2 font-weight-bold  rounded ">
							{teamManagement.search}
						</button>
					</div> */}

				<div className="col-md-1" style={{ cursor: "pointer" }}>
					<i class="fas fa-retweet p-2"></i>
					<span
						className="font-weight-bold "
						onClick={() =>
							setFilterData({
								hotel_id: "",
								name: "",
								national_id: "",
								passport_number: "",
								date: "",
							})
						}
					>
						{commons.reset}
					</span>
				</div>
			</div>
		</>
	);
}
