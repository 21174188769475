import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { formatPostBranch } from "data/lab";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { createBranch, editBranch } from "services/lab";
import { fetchAreas, fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

export default function AddBranch({
	modal,
	toggle,
	branch,
	setBranch,
	fetchData,
}) {
	const { teamManagement, branches, Labs } = Locale;

	const locale = localStorage.getItem("currentLocale") || "en";

	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [areas, setAreas] = useState([]);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function fetchLookups() {
			if (branch.country?.value) {
				const res = await fetchCities(branch.country?.value);
				const formatted = res.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [branch.country]);

	useEffect(() => {
		async function fetchLookups() {
			if (branch.city?.value) {
				const res = await fetchAreas(branch.city?.value);
				const formatted = res.map((city) => ({
					value: city.id,
					label: city.names[locale],
				}));
				setAreas(formatted);
			}
		}
		fetchLookups();
	}, [branch.city]);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "branchName", value: branch.branchName },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: branch.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: branch.country },
				{ required: true }
			),
			...validate({ name: "city", value: branch.city }, { required: true }),
			...validate({ name: "area", value: branch.area }, { required: true }),
			...validate(
				{ name: "address", value: branch.address },
				{ required: true, min: 3 }
			),
			...validate(
				{
					name: "phone",
					value: branch.phone,
				},
				{ required: true, phone: true }
			),
		});
	};

	//Edit Branch
	const update = async () => {
		const data = formatPostBranch(branch);
		const res = await editBranch(data, branch.id);
		if (res.status === 201) {
			toggle();
			fetchData();
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	//Create Branch
	const create = async () => {
		const data = formatPostBranch(branch);
		const res = await createBranch(data);
		if (res.status === 201) {
			toggle();
			fetchData();
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	useEffect(() => {
		if (isFormValid(errors)) {
			if (branch.id) {
				update();
			} else {
				create();
			}
		}
	}, [isErrorLoaded]);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setBranch({ ...branch, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setBranch({ ...branch, city: "", [name]: value });
			} else setBranch({ ...branch, [name]: value });
		}
	};

	const submit = () => {
		checkFormErrors();
		// toggle();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	return (
		<>
			<button onClick={toggle}>{branches.addBranch}</button>

			<Modal className={`add-model`} isOpen={modal} toggle={() => toggle()}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{branches.addBranch}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<TextField
							type="text"
							label={Labs.branchName}
							placeholder={Labs.branchName}
							value={branch?.branchName}
							name="branchName"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.branchName?.required ? "danger" : ""}
							errors={errors?.branchName}
							min={3}
						/>

						<TextField
							type="email"
							label={teamManagement.email}
							placeholder={teamManagement.email}
							value={branch.email}
							name="email"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.email?.required ? "danger" : ""}
							errors={errors?.email}
						/>

						<SelectField
							type="text"
							name="country"
							label={teamManagement.country}
							placeholder={teamManagement.country}
							errors={errors.country}
							color={errors.country?.required ? "danger" : ""}
							options={countries}
							onChange={(e) => {
								handleChange({ name: "country", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "country", value: e },
										{
											required: true,
										}
									),
								});
								setBranch({
									...branch,
									country: e,
									city: "",
									area: "",
									phone: "",
								});
							}}
							value={branch.country}
						/>

						<SelectField
							type="text"
							name="city"
							label={teamManagement.city}
							placeholder={teamManagement.city}
							errors={errors.city}
							color={errors.city?.required ? "danger" : ""}
							options={cities}
							onChange={(e) => {
								handleChange({ name: "city", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "city", value: e },
										{
											required: true,
										}
									),
								});
							}}
							value={branch.city}
						/>

						<SelectField
							type="text"
							name="area"
							label={teamManagement.area}
							placeholder={teamManagement.areaPlaceholder}
							errors={errors?.area}
							color={errors?.area?.required ? "danger" : ""}
							options={areas}
							onChange={(e) => {
								handleChange({ name: "area", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "area", value: e },
										{
											required: true,
										}
									),
								});
							}}
							value={branch.area}
						/>

						<TextField
							type="text"
							name="address"
							label={teamManagement.address}
							placeholder={teamManagement.address}
							value={branch.address}
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										min: 3,
									}),
								});
							}}
							errors={errors?.address}
							color={errors?.address?.required ? "danger" : ""}
							min={3}
						/>
						<TextField
							type="text"
							name="phone"
							extraTextPosition={"prepend"}
							errors={errors.phone}
							color={errors.phone?.required ? "danger" : ""}
							extraText={
								branch.country.value ? "+" + branch.country.value : "---"
							}
							label={teamManagement.mobileNumber}
							placeholder={teamManagement.mobileNumber}
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
										phone: true,
									}),
								});
							}}
							value={branch.phone}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={submit}>
						{branches.addBranch}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
