import React from "react";
import Locale from "translations";
import Filter from "./Filter";
import ListUsers from "./ListUsers";
export default function TeamManagement() {
	const { teamManagement } = Locale;

	return (
		<>
			<section className="bg-gray py-3 medical-section Users">
				<div className="container-fluid">
					<div className="col-md-12">
						<div className="row">
							<div className="itemBoxCircle">
								<div className="boxCircle d-flex align-items-center">
									<span className="counter">6</span>
									<span className="circle">
										<i className="fas fa-circle"></i>
									</span>
									<p>{teamManagement.userCount}</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle d-flex align-items-center">
									<span className="counter green">6</span>
									<span className="circle green">
										<i className="fas fa-circle"></i>
									</span>
									<p>{teamManagement.activationUsers}</p>
								</div>
							</div>
							<div className="itemBoxCircle">
								<div className="boxCircle d-flex align-items-center">
									<span className="counter red">0</span>
									<span className="circle red">
										<i className="fas fa-circle"></i>
									</span>
									<p>{teamManagement.inactivateUsers}</p>
								</div>
							</div>
						</div>
					</div>

					<div className="medical-result">
						<section className="reservation-section">
							<div className="reservation-box m-0">
								<div className="TeamManagement-header">
									<div className="TeamManagement-title">
										{" "}
										{teamManagement.teamManagement}
									</div>
								</div>

								<Filter />
								<ListUsers />
							</div>
						</section>
					</div>
				</div>
			</section>
		</>
	);
}
