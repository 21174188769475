import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import validate from "helpers/validate";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function AddSection() {
	const { teamManagement, branches, Labs } = Locale;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [branch, setBranch] = useState({
		branchName: "",
		email: "",
		country: "",
		address: "",
		phone: "",
	});
	const [countries, setCountries] = useState([]);

	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "branchName", value: branch.branchName },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: branch.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: branch.country },
				{ required: true }
			),
			...validate(
				{ name: "address", value: branch.address },
				{ required: true, min: 3 }
			),
			...validate(
				{
					name: "phone",
					value: branch.phone,
				},
				{ required: true, phone: true }
			),
		});
	};

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setBranch({ ...branch, [name]: value });
		}
	};

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
		alert("Submitted");
	};

	// useEffect(() => {
	// 	async function fetchLookups() {
	// 		const res = await fetchCountries();
	// 		const format = res.map((e) => ({
	// 			value: e.id,
	// 			label: e.names[locale],
	// 		}));
	// 		setCountries(format);
	// 	}
	// 	fetchLookups();
	// }, []);

	return (
		<>
			<button onClick={toggle}>Add Section</button>

			<Modal className={`add-model`} isOpen={modal} toggle={toggle}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">Add Section</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<TextField
							type="text"
							label={Labs.branchName}
							placeholder={Labs.branchName}
							value={branch?.branchName}
							name="branchName"
							onChange={(e) => {
								handleChange(e);
								setErrors({
									...errors,
									...validate(e.target, {
										required: true,
									}),
								});
							}}
							color={errors?.branchName?.required ? "danger" : ""}
							errors={errors?.branchName}
							min={3}
						/>

						<SelectField
							type="text"
							name="country"
							label={teamManagement.governorate}
							placeholder={teamManagement.governorate}
							errors={errors?.country}
							color={errors?.country?.required ? "danger" : ""}
							options={countries}
							onChange={(e) => {
								handleChange({ name: "country", value: e });
								setErrors({
									...errors,
									...validate(
										{ name: "country", value: e },
										{
											required: true,
										}
									),
								});
							}}
							value={branch.governorate}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={submit}>
						{branches.addBranch}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
