import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { formatGetCompany, formatPostCompany } from "data/company";
import validate, { isFormValid, validatePhone } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { createCompany, editCompany, getCompany } from "services/admin";
import { fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

export default function CompanyInfo() {
	const { teamManagement, companies } = Locale;
	const locale = localStorage.getItem("currentLocale") || "en";
	const [company, setCompany] = useState({
		name: "",
		domain: "",
		website: "",
		country: "",
		city: "",
		address: "",
		email: "",
		phone: "",
		userName: "",
		userEmail: "",
		userPhone: "",
	});
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const { id } = useParams();
	// console.log(id);
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: company.name },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "domain", value: company.domain },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "address", value: company.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: company.email },
				{ required: true, email: true }
			),
			...validate(
				{ name: "country", value: company.country },
				{ required: true }
			),
			...validate({ name: "city", value: company.city }, { required: true }),
			...validate(
				{
					name: "phone",
					value: validatePhone(company.phone, company.country?.value),
				},
				{ required: true, phone: true }
			),
			...validate(
				{ name: "userName", value: company.userName },
				{ required: true }
			),
			...validate(
				{ name: "userEmail", value: company.userEmail },
				{ required: true }
			),
			...validate(
				{
					name: "userPhone",
					value: validatePhone(company.userPhone, company.country?.value),
				},
				{ required: true }
			),
		});
	};

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function fetchLookups() {
			if (company.country?.value) {
				const res = await fetchCities(company.country?.value);
				const formatted = res.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [company.country]);

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id) {
				const data = formatPostCompany(company);
				const res = await editCompany(data, id);
				if (res.status === 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostCompany(company);
				const res = await createCompany(data);
				if (res.status === 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		}
	}, [isErrorLoaded]);

	useEffect(async () => {
		if (id) {
			const res = await getCompany(id);
			if (res.status === 200) {
				const formatted = formatGetCompany(res.data.data);
				setCompany(formatted);
				// console.log("company", formatted);
			}
		}
	}, []);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setCompany({ ...company, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setCompany({ ...company, city: "", [name]: value });
			} else setCompany({ ...company, [name]: value });
		}
	};

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	// console.log("----company----", company);
	// console.log("----err----", errors);

	return (
		<>
			<section className="bg-gray py-3 reservation-section">
				<div className="container-fluid">
					<div className="lab-info-box ">
						<div className="TeamManagement-header">
							<div className="TeamManagement-title">
								{" "}
								{companies.companyInfo}
							</div>
							<div className="TeamManagement-taps"></div>
						</div>
						<div className="col-md-12 p-d-20">
							<div className="row">
								<div className="col-md-6">
									<TextField
										type="text"
										name="name"
										label={companies.companyName}
										placeholder={companies.companyName}
										errors={errors.name}
										color={errors.name?.required ? "danger" : ""}
										min={3}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													// min: 3,
												}),
											});
										}}
										value={company.name}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="domain"
										label={companies.domainName}
										placeholder={companies.domainName}
										errors={errors.domain}
										color={errors.domain?.required ? "danger" : ""}
										min={3}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
										}}
										disabled={id}
										value={company.domain}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="website"
										label={companies.website}
										placeholder={companies.website}
										onChange={(e) => {
											handleChange(e);
										}}
										value={company.website}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="address"
										label={companies.address}
										placeholder={companies.address}
										errors={errors.address}
										color={errors.address?.required ? "danger" : ""}
										min={3}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													min: 3,
												}),
											});
										}}
										value={company.address}
									/>
								</div>
								<div className="col-md-6">
									<SelectField
										type="text"
										name="country"
										label={companies.country}
										placeholder={companies.country}
										errors={errors.country}
										color={errors.country?.required ? "danger" : ""}
										options={countries}
										onChange={(e) => {
											handleChange({ name: "country", value: e });
											setErrors({
												...errors,
												...validate(
													{ name: "country", value: e },
													{
														required: true,
													}
												),
											});
										}}
										value={company.country}
									/>
								</div>
								<div className="col-md-6">
									<SelectField
										type="text"
										name="city"
										label={companies.city}
										placeholder={companies.city}
										errors={errors.city}
										color={errors.city?.required ? "danger" : ""}
										options={cities}
										onChange={(e) => {
											handleChange({ name: "city", value: e });
											setErrors({
												...errors,
												...validate(
													{ name: "city", value: e },
													{
														required: true,
													}
												),
											});
										}}
										value={company.city}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="phone"
										extraTextPosition={"prepend"}
										errors={errors.phone}
										color={errors.phone?.required ? "danger" : ""}
										extraText={
											company.country.value
												? "+" + company.country.value
												: "---"
										}
										label={companies.phone}
										placeholder={"02335489793"}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													phone: true,
												}),
											});
										}}
										value={company.phone}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="email"
										label={companies.email}
										errors={errors.email}
										color={errors.email?.required ? "danger" : ""}
										placeholder={"0123456789"}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
										}}
										value={company.email}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="userName"
										label={companies.userName}
										errors={errors.userName}
										color={errors.userName?.required ? "danger" : ""}
										placeholder={"0123456789"}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
										}}
										value={company.userName}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="userEmail"
										label={companies.userEmail}
										errors={errors.userEmail}
										color={errors.userEmail?.required ? "danger" : ""}
										placeholder={"0123456789"}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
										}}
										value={company.userEmail}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										type="text"
										name="userPhone"
										label={companies.userPhone}
										errors={errors.userPhone}
										color={errors.userPhone?.required ? "danger" : ""}
										extraTextPosition={"prepend"}
										extraText={
											company.country.value
												? "+" + company.country.value
												: "---"
										}
										placeholder={"0123456789"}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													phone: true,
												}),
											});
										}}
										value={company.userPhone}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 p-d-15 over-flow">
					<Button onClick={submit} className="btn btn-add float-left-local">
						{id ? teamManagement.edit : teamManagement.add}
					</Button>
				</div>
			</section>
		</>
	);
}
