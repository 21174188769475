import React from "react";
import Locale from "translations";

export default function Group() {
	const { teamManagement } = Locale;
	return (
		<>
			<tr>
				<td>اسم المعمل</td>
				<td>القاهرة</td>
				<td>المعادي</td>
				<th>email@email.com</th>
				<td>
					<i className="far fa-check-circle btn fa-2x text-success"></i>
				</td>
			</tr>
		</>
	);
}
