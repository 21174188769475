import TextField from "components/shared/TextField";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";

export default function ChangePassword({
	modalChangePassword,
	toggleChangePassword,
}) {
	const { teamManagement } = Locale;

	return (
		<>
			<Modal
				className={`add-model`}
				isOpen={modalChangePassword}
				toggle={toggleChangePassword}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">
						{teamManagement.changePassword}
					</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="col-md-12">
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.NewPassword}
									placeholder={"*************"}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.ConfirmTheNewPassword}
									placeholder={"****************"}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggleChangePassword}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggleChangePassword}>
						{teamManagement.edit}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
