import React, { useState } from "react";
import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import Locale from "translations";
import GroupTable from "./GroupTable";

export default function ListGroups() {
	const { teamManagement, groups } = Locale;
	const [isOpen, setIsOpen] = useState(false);
	const toggleButton = () => setIsOpen(!isOpen);

	//Collapse 1
	const [isOpen1, setIsOpen1] = useState(false);
	const toggleButton1 = () => setIsOpen1(!isOpen1);

	return (
		<>
			<Row className="m-0 bg-gray-100">
				<Col sm="12">
					<div className="shadow-sm product-build__product-collpase branch-collapse">
						<div className="button-collapse">
							<div className="title-style-container">
								<div
									className="icon-items-section d-flex align-items-center"
									onClick={toggleButton1}
								>
									<i
										className={`fas fa-fw fa-lg text-white ${
											isOpen1 !== true ? "fa-plus" : "fa-minus"
										} `}
									></i>
								</div>

								<div className="right-items-container">
									<div className="product-content-items w-100">
										<p className="mx-2">{groups.groupName}</p>

										<div className="d-flex align-items-center">
											<div className="img-circle-wrapper">
												<span className="text-white">1</span>
											</div>
											<h6>{groups.users}</h6>
										</div>

										<div className="d-flex align-items-center mx-2 pointer">
											<i className="far fa-edit fa-fw fa-lg  mx-1"></i>
											<h6 className="">{groups.edit}</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Collapse isOpen={isOpen1}>
							<Card>
								<CardBody>
									{/* if length == 0 */}

									{/* <p className="text-muted mt-5 h1 text-center">
									<i className="fas h1 fa-exclamation-triangle "></i>
								</p>
								<div className="text-center pt-5">
									<i className="fas h1 fa-cog text-muted fa-spin"></i>
								</div> */}

									<GroupTable />
								</CardBody>
							</Card>
						</Collapse>
					</div>
				</Col>
			</Row>
		</>
	);
}
