import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import { Link } from "react-router-dom";
import Locale from "translations";

export default function Filter({ countries, setCountries }) {
	const { teamManagement } = Locale;

	// console.log("countries", countries);

	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={""}
						placeholder={teamManagement.country}
						name="country"
						options={countries}
						onChange={(e) => {}}
					/>
				</div>
				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={""}
						placeholder={teamManagement.status}
						name="country"
						options={["countries", "countries"]}
						onChange={(e) => {
							setCountries({
								...countries,
								classes: { id: e.value, name: e.label },
							});
						}}
					/>
				</div>

				<div className="btn-boxs">
					<Link to="/admin/company-info">{teamManagement.addCompany}</Link>
				</div>
			</div>
		</>
	);
}
