import axios from "axios";
const authURL = process.env.REACT_APP_API_URL + "/api/central/auth/login";
const companyURL = process.env.REACT_APP_API_URL + "/api/central/companies";
const adminsURL = process.env.REACT_APP_API_URL + "/api/central/admins";

export const centralLogin = async (data) => {
	return await axios
		.post(authURL, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createCompany = async (data) => {
	return await axios
		.post(`${companyURL}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editCompany = async (data, id) => {
	return await axios
		.put(`${companyURL}/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listCompanies = async () => {
	return await axios
		.get(companyURL)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const getCompany = async (id) => {
	return await axios
		.get(`${companyURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatus = async (status) => {
	return await axios
		.get(`${companyURL}/change-status`, status)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listAdmins = async () => {
	return await axios
		.get(`${adminsURL}`)
		.then((res) => res)
		.catch((err) => err.response);
};
