import CheckBox from "components/shared/Chekbox";
import Pagination from "components/shared/Pagination";
import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { changeStatusTraveller, travelerLabs } from "services/lab";
import Locale from "translations";
import Traveler from "./Traveler";
export default function List({
	filter,
	status,
	amount,
	setAmount,
	policie,
	setPolicie,
	setfilter,
}) {
	const { Labs, commons } = Locale;
	const [listRun, setListRun] = useState(false);
	const [labs, setLabs] = useState([]);
	const [modalPrice, setModalPrice] = useState(false);
	const [errors, setErrors] = useState({});
	const [isErrors, setIsErrors] = useState(false);
	const [checked, setChecked] = useState({
		isChecked: false,
		isAllChecked: false,
		policies: [],
	});
	const [meta, setmeta] = useState({});
	const toggle = () => {
		setModalPrice(!modalPrice);
		if (modalPrice) {
			setChecked({
				...checked,
				isChecked: false,
				policies: [],
			});
			setAmount({});
		}
	};

	/* Filter and start component */
	useEffect(() => {
		async function fetchDataFilter() {
			const labs = await travelerLabs({
				...filter,
				gender: filter.gender?.value,
				request_date: filter.request_date
					? moment(filter.request_date).format("DD-MM-YYYY")
					: "",
				status: status,
				page: filter.status === status ? filter.page : 1,
			});
			setChecked({
				isChecked: false,
				isAllChecked: false,
				policies: [],
			});

			setLabs(labs.data.data);
			setmeta(labs.data.meta);
		}

		const filterHandler = setTimeout(() => {
			fetchDataFilter();
		}, 500);
		return () => {
			clearTimeout(filterHandler);
		};
	}, [filter, listRun]);

	/* form amount popup and change traveller status */
	useEffect(() => {
		if (isFormValid(errors)) {
			async function fetchData() {
				const res = await changeStatusTraveller({
					amount: amount,
					policies: checked.policies,
				});
				if (res.status === 201 || res.status === 200) {
					store.addNotification({
						title: "info!",
						message: commons.priceNotification,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
					/* make list run  */
					setListRun(!listRun);
				}
			}
			fetchData();
			toggle();
		}
	}, [isErrors]);

	/* checks amount errors  */
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "amount", value: amount.amount },
				{ required: true, number: true }
			),
		});
	};
	/* sumbit change submit */
	const submit = () => {
		checkFormErrors();
		if (!isErrors) {
			setIsErrors(true);
		} else {
			setIsErrors(false);
		}
	};

	/* one checkbox  handel  */
	const handleCheck = (e) => {
		const check = e.target.checked;
		const id = e.target.id;

		if (check) {
			setChecked({
				...checked,
				/* for button apperance  */
				isChecked: true,
				policies: [...checked.policies, +id],
			});
		} else {
			const filtered = checked.policies.filter((item) => {
				return item != id;
			});
			setChecked({
				...checked,
				policies: filtered,
				isChecked: filtered.length === 0 ? false : true,
			});
		}
	};
	/* selelct and select all handel  */
	useEffect(() => {
		if (status === "pending" && AllTravelers.length > 0) {
			const checkBox = document.getElementById("selectAll");

			if (checked.policies.length === labs.length) {
				checkBox.indeterminate = false;
				checkBox.checked = checked.policies.length === 0 ? false : true;
			} else if (
				checked.policies.length != labs.length &&
				checked.policies.length != 0 &&
				checked.isAllChecked === true
			) {
				checkBox.indeterminate = true;
				checkBox.checked = false;
			} else if (checked.policies.length === 0) {
				checkBox.indeterminate = false;
				checkBox.checked = false;
			} else if (
				checked.policies.length != labs.length &&
				checked.policies.length != 0 &&
				checked.isAllChecked === false
			) {
				checkBox.indeterminate = true;
				checkBox.checked = false;
			}
		}
	}, [checked.policies]);

	/* all checkbox handel */
	const handleAllCheck = (e) => {
		const check = e.target.checked;

		if (check) {
			const filtered = labs.reduce((acc, item) => {
				acc.push(item.id);
				return acc;
			}, []);
			setChecked({
				...checked,
				isChecked: true,
				isAllChecked: true,
				policies: filtered,
			});
		} else {
			setChecked({
				...checked,
				isChecked: false,
				isAllChecked: false,
				policies: [],
			});
		}
	};
	console.log(checked);

	const goTo = async (page = 1) => {
		setfilter({ ...filter, page: page, status: status });
		setChecked({
			isChecked: false,
			isAllChecked: false,
			policies: [],
		});
	};
	/* change status while filter input has value */
	useEffect(() => {
		setfilter({
			national_id: "",
			passport_number: "",
			request_date: "",
			gender: null,
			page: 1,
		});
	}, [status]);

	const AllTravelers =
		labs && labs.length > 0
			? labs.map((lab, index) => (
					<Traveler
						key={index}
						setLabs={setLabs}
						lab={lab}
						policie={policie}
						setPolicie={setPolicie}
						toggle={toggle}
						handleCheck={handleCheck}
						checked={checked}
						setChecked={setChecked}
						status={status}
					/>
			  ))
			: "";

	return (
		<>
			<div className="medical-result">
				<section className="reservation-section">
					<div className="reservation-box m-0">
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<div className="reservation-table">
									{/* select all button  */}
									{checked.isChecked && (
										<div className="row justify-content-around p-2">
											<div className="col-10 d-flex justify-content-end ">
												<button
													className=" btn btn-outline-success font-weight-bold  p-2  "
													onClick={toggle}
												>
													{Labs.issueAll}
												</button>
											</div>
										</div>
									)}

									<table className="table table-striped mb-0">
										<thead>
											<tr className="bg-grey text-white">
												{/* <th scope="col">{Labs.branchName}</th> */}
												{status === "pending" && AllTravelers.length > 0 && (
													<th scope="col">
														<CheckBox
															id="selectAll"
															text={Labs.selectAll}
															onChange={handleAllCheck}
														/>
													</th>
												)}
												<th scope="col">{Labs.pilgrimName}</th>
												<th scope="col">{Labs.passportNumber}</th>
												<th scope="col">{Labs.nationalId}</th>
												<th scope="col">{Labs.gender}</th>
												<th scope="col">{Labs.age}</th>
												<th scope="col">
													{status === "pending"
														? Labs.applicationDate
														: Labs.issueDate}
												</th>
												<th scope="col">{Labs.statusCheck}</th>
												<th scope="col">{Labs.actions}</th>

												{/* <th scope="col">{Labs.analysisResults}</th> */}
												{/* <th scope="col">{Labs.analysisHistory}</th> */}
											</tr>
										</thead>
										<tbody>
											{AllTravelers.length > 0 ? (
												AllTravelers
											) : (
												<tr>
													<td colSpan="11">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{Labs.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>

									<div>
										<Modal isOpen={modalPrice} toggle={toggle}>
											<ModalBody className="d-flex align-items-center justify-content-center">
												<div className="w-100">
													<TextField
														label={commons.price}
														name="price"
														onChange={(e) => {
															setAmount({ amount: e.target.value });
															setErrors({
																...errors,
																...validate(e.target, {
																	number: true,
																	required: true,
																}),
															});
														}}
														value={amount.amount}
														placeholder={commons.pricePlaceholder}
														extraTextPosition={"append"}
														extraText={"EGP"}
														color={
															errors?.price?.number || errors?.price?.required
																? "danger"
																: ""
														}
														errors={errors?.price}
													/>
												</div>
											</ModalBody>
											<ModalFooter>
												<Button
													color="primary"
													onClick={() => {
														submit();
													}}
												>
													{commons.save}
												</Button>

												<Button
													color="secondary"
													onClick={() => {
														toggle();
													}}
												>
													{commons.back}
												</Button>
											</ModalFooter>
										</Modal>
									</div>

									{/* <div className="d-flex justify-content-between align-items-center mt-2 p-2">
										<div>إظهار 1 إلى 10 من 4،951 حجوزات</div>
										<nav aria-label="Page navigation example">
											<ul className="pagination">
												<li className="page-item">
													<a
														className="page-link ml-2"
														href="#"
														aria-label="Previous"
													>
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-right"></i>
														</span>
														<span className="sr-only">Previous</span>
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														4
													</a>
												</li>
												<li className="page-item mr-2">
													<a className="page-link" href="#" aria-label="Next">
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-left"></i>
														</span>
														<span className="sr-only">Next</span>
													</a>
												</li>
											</ul>
										</nav>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<Pagination info={meta} goTo={goTo} items={15} />
				</section>
			</div>
		</>
	);
}
