import Model from "components/model";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useEffect, useState } from "react";
import { branchesList } from "services/lab";
import Locale from "translations";
import AddBranch from "./AddBranch";

export default function FilterBranch({
	modal,
	toggle,
	branch,
	setBranch,
	setBranchList,
	fetchData,
}) {
	const { teamManagement } = Locale;

	const [filterState, setFilterState] = useState({
		status: "",
	});

	const [deleteModal, setDeleteModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		active: false,
	});

	const toggleDelete = () => {
		setDeleteModal({
			isOpen: !deleteModal.isOpen,
			type: "delete",
			title: "Delete Branch",
		});
	};

	let myID = branch.id;
	const deleteBranch = async () => {
		setDeleteModal({ ...modal, active: true });
		const res = await deleteBranch(myID);
		if (res.status === 200) {
			setDeleteModal({});
			setBranchList(res.data.data.data);
		} else {
			setDeleteModal({ ...modal, active: false, isOpen: false });
		}
	};

	useEffect(() => {
		async function fetchData() {
			const data = {
				name: filterState.name,
				status: filterState.status.label,
			};

			const res = await branchesList(data);
			setBranchList(res.data.data.data);
		}
		fetchData();
	}, [filterState.status, filterState.name]);

	return (
		<>
			<Model
				isOpen={deleteModal.isOpen}
				toggle={toggleDelete}
				type={deleteModal.type}
				title={deleteModal.title}
				submit={deleteBranch}
				disabled={deleteModal.active}
			>
				<div className="col p-3">{teamManagement.deleteProductQoute}</div>
			</Model>

			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.branchName}
						hasLabel={false}
						value={filterState.name}
						onChange={(e) => {
							setFilterState({
								...filterState,
								name: e.target.value,
							});
						}}
					/>
				</div>
				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={filterState.status}
						placeholder={teamManagement.status}
						name="country"
						options={[
							{
								value: 0,
								label: "active",
							},
							{
								value: 1,
								label: "inactive",
							},
						]}
						onChange={(e) => {
							setFilterState({
								...filterState,
								status: e,
							});
						}}
					/>
				</div>

				<div className="btn-boxs">
					<AddBranch
						modal={modal}
						toggle={toggle}
						branch={branch}
						setBranch={setBranch}
						setBranchList={setBranchList}
						fetchData={fetchData}
					/>
				</div>
				<div className="clearfix"></div>
			</div>
		</>
	);
}
