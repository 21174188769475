import "@fortawesome/fontawesome-free/css/all.min.css";
import Layout from "components/layout";
import Loader from "components/Loader";
import { useLabsState } from "context/global";
import moment from "moment";
import "moment/locale/ar";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import AxiosConfig from "services/axios-config";
import Locale from "translations";
import "./scss/index.scss";

function App() {
	AxiosConfig();

	const { locale, loading } = useLabsState();
	Locale.setLanguage(locale);
	document
		.getElementsByTagName("html")[0]
		.setAttribute("dir", locale === "ar" ? "rtl" : "ltr");
	moment.locale("en");

	return (
		<div
			style={{
				direction: locale === "ar" ? "rtl" : "ltr",
				textAlign: locale === "ar" ? "right" : "left",
				fontFamily: locale === "ar" ? "Cairo" : "Roboto",
			}}
		>
			{loading && <Loader />}
			<ReactNotification />
			<Router>
				<Layout>{Routes}</Layout>
			</Router>
		</div>
	);
}

export default App;
