import React, { useEffect, useState } from "react";
import { listTravellers } from "services/branch";
import Locale from "translations";
import Traveler from "./Traveler";

export default function List() {
	const { Labs } = Locale;
	const [travelers, setTravelers] = useState([]);

	const fetchData = async () => {
		const Travelers = await listTravellers();
		setTravelers(Travelers.data);
	};
	useEffect(() => {
		async function fetchData() {
			const Travelers = await listTravellers();
			setTravelers(Travelers.data);
		}
		fetchData();

		// setTravelers(companies);

		// axios.get("../travelers.json").then((res) => {
		// 	setTravelers(res.data.Travelers);
		// });
	}, []);

	const AllTravelers =
		travelers.length > 0
			? travelers.map((traveler, index) => (
					<Traveler
						traveler={traveler}
						index={index}
						fetchData={fetchData}
						// removeItem={removeItem}
					/>
			  ))
			: [];

	return (
		<>
			<div className="medical-result">
				<div className="no-result d-none">
					<i className="fas fa-exclamation-triangle"></i>
					<p>لا توجد نتائج مطابقة للبحث</p>
				</div>

				<section className="reservation-section">
					<div className="reservation-box m-0">
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<div className="reservation-table">
									<table className="table table-striped mb-0">
										<thead>
											<tr className="bg-grey text-white">
												<th scope="col">{Labs.image}</th>
												<th scope="col">{Labs.pilgrimName}</th>
												<th scope="col">{Labs.passportNumber}</th>
												<th scope="col">{Labs.nationalId}</th>
												<th scope="col">{Labs.gender}</th>
												<th scope="col">{Labs.age}</th>
												<th scope="col">{Labs.departureDate}</th>
												<th scope="col">{Labs.statusCheck}</th>
												<th scope="col">{Labs.examinationDate}</th>
												<th scope="col">{Labs.analysisResults}</th>
												<th scope="col">{Labs.tools}</th>
											</tr>
										</thead>
										<tbody>
											{AllTravelers.length > 0 ? (
												AllTravelers
											) : "loading" || true ? null : (
												<tr>
													<td colSpan="11">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{Labs.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>

									{/* <div className="d-flex justify-content-between align-items-center mt-2 p-2">
										<div>إظهار 1 إلى 10 من 4،951 حجوزات</div>
										<nav aria-label="Page navigation example">
											<ul className="pagination">
												<li className="page-item">
													<a
														className="page-link ml-2"
														href="#"
														aria-label="Previous"
													>
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-right"></i>
														</span>
														<span className="sr-only">Previous</span>
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														4
													</a>
												</li>
												<li className="page-item mr-2">
													<a className="page-link" href="#" aria-label="Next">
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-left"></i>
														</span>
														<span className="sr-only">Next</span>
													</a>
												</li>
											</ul>
										</nav>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
