import labImage from "assets/images/dashboard/lab-img.svg";
import userImage from "assets/images/dashboard/user-img.svg";
import React from "react";
import Locale from "translations";

export default function Counts() {
	const { statistics } = Locale;
	return (
		<>
			{/* Start number of branches*/}
			{/*
			<div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
				<h6 className="title">{statistics.numberBranches}</h6>
				<div className="d-flex justify-content-between align-items-center">
					<div className="img-box">
						<img src={branchImage} alt="branchImage" />
					</div>
					<div className="number">210</div>
				</div>
			</div>

		  */}
			{/* end number of branches*/}

			<div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
				<h6 className="title">{statistics.numberLabs}</h6>
				<div className="d-flex justify-content-between align-items-center">
					<div className="img-box">
						<img src={labImage} alt="branchImage" />
					</div>
					<div className="number">36</div>
				</div>
			</div>
			<div className="count-box bg-white mb-2 p-3 rounded shadow-sm">
				<h6 className="title">{statistics.numberUsers}</h6>
				<div className="d-flex justify-content-between align-items-center">
					<div className="img-box">
						<img src={userImage} alt="branchImage" />
					</div>
					<div className="number">13</div>
				</div>
			</div>
		</>
	);
}
