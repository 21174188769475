import Pagination from "components/shared/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { filtetTravelers } from "services/lab";
import Locale from "translations";
import Traveler from "./Traveler";

export default function List({ filter, status, setfilter }) {
	const { Labs, teamManagement } = Locale;
	const [travelers, setTravelers] = useState([]);
	const [meta, setMeta] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const res = await filtetTravelers({
				...filter,
				gender: filter.gender?.value,
				request_date: filter.request_date
					? moment(filter.request_date).format("DD-MM-YYYY")
					: "",
				status: status,
			});

			setTravelers(res.data.data);
			setMeta(res.data.meta);
		}

		const filterHandler = setTimeout(() => {
			fetchData();
		}, 500);
		return () => {
			clearTimeout(filterHandler);
		};
	}, [filter]);

	const goTo = async (page = 1) => {
		setfilter({ ...filter, page: page, status: status });
	};

	useEffect(() => {
		setfilter({
			lab_name: "",
			national_id: "",
			passport_number: "",
			company_name: "",
			request_date: "",
			license_number: "",
			gender: null,
			page: 1,
		});
	}, [status]);

	// console.log("travelers", travelers);

	const AllTravelers =
		travelers && travelers.length > 0
			? travelers.map((traveler, index) => (
					<Traveler
						lab={traveler}
						index={index}
						// removeItem={removeItem}
					/>
			  ))
			: "";

	// console.log("travelers", travelers);

	return (
		<>
			<div className="medical-result">
				{AllTravelers.length > 0 ? (
					<section className="reservation-section">
						<div className="reservation-box m-0">
							<div className="tab-content" id="pills-tabContent">
								<div
									className="tab-pane show active"
									id="pills-home"
									role="tabpanel"
									aria-labelledby="pills-home-tab"
								>
									<div className="reservation-table">
										<table className="table table-striped mb-0">
											<thead>
												<tr className="bg-grey text-white">
													<th scope="col">{Labs.companyName}</th>
													<th scope="col">{Labs.labName}</th>
													<th scope="col">{Labs.pilgrimName}</th>
													<th scope="col">{Labs.passportNumber}</th>
													<th scope="col">{Labs.nationalId}</th>
													<th scope="col">{Labs.gender}</th>
													<th scope="col">{Labs.age}</th>
													<th scope="col">
														{status === "pending"
															? Labs.applicationDate
															: Labs.issueDate}
													</th>

													<th scope="col" colSpan="2">
														{Labs.status}
													</th>
												</tr>
											</thead>
											<tbody>
												{AllTravelers.length > 0 ? (
													AllTravelers
												) : "loading" || true ? null : (
													<tr>
														<td colSpan="11">
															<div className="product-build__product-no-data">
																<i className="fas fa-info-circle fa-lg"></i>{" "}
																<h4>{Labs.noResult}</h4>
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>

										<div>
											<Pagination info={meta} goTo={goTo} items={15} />
										</div>

										{/* <div className="d-flex justify-content-between align-items-center mt-2 p-2">
										<div>إظهار 1 إلى 10 من 4،951 حجوزات</div>
										<nav aria-label="Page navigation example">
											<ul className="pagination">
												<li className="page-item">
													<a
														className="page-link ml-2"
														href="#"
														aria-label="Previous"
													>
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-left"></i>
														</span>
														<span className="sr-only">Previous</span>
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														4
													</a>
												</li>
												<li className="page-item mr-2">
													<a className="page-link" href="#" aria-label="Next">
														<span aria-hidden="true" className="p-0">
															<i className="fas fa-chevron-right"></i>
														</span>
														<span className="sr-only">Next</span>
													</a>
												</li>
											</ul>
										</nav>
									</div> */}
									</div>
								</div>
							</div>
						</div>
					</section>
				) : (
					<div className="no-result">
						<i className="fas fa-exclamation-triangle"></i>
						<p>{Labs.noResult}</p>
					</div>
				)}
			</div>
		</>
	);
}
