import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
const tenantURL = `https://${subdomain}.${domain}/api/tenant`;

export const listUsers = async (page = 1) => {
	return await axios
		.get(tenantURL + "/companies/users?page=" + page)
		.then((res) => res)
		.catch((err) => err.response);
};
export const listGroups = async (page = 1) => {
	return await axios
		.get(`${tenantURL}/companies/groups?page=${page}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listGroupsDp = async () => {
	return await axios
		.get(`${tenantURL}/companies/users/groups`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const listPermissions = async () => {
	return await axios
		.get(`${tenantURL}/companies/groups/permissions`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addGroupAPI = async (data) => {
	return await axios
		.post(`${tenantURL}/companies/groups`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addUserAPI = async (data) => {
	return await axios
		.post(`${tenantURL}/companies/users`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changeStatus = async (id, status) => {
	return await axios
		.patch(`${tenantURL}/companies/users/${id}/${status}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const changePasswordAPI = async (id, data) => {
	return await axios
		.patch(`${tenantURL}/companies/users/${id}/change-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editGroupAPI = async (id, data) => {
	return await axios
		.put(`${tenantURL}/companies/groups/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const editUserAPI = async (id, data) => {
	return await axios
		.put(`${tenantURL}/companies/users/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getUserData = async (id) => {
	return await axios
		.get(`${tenantURL}/companies/users/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getGroupData = async (id) => {
	return await axios
		.get(`${tenantURL}/companies/groups/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
