import CheckBox from "components/shared/Chekbox";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

export default function Filter({
	activePage,
	setLabs,
	labs,
	meta,
	setRes,
	goTo,
	setfilter,
	filter,
}) {
	const { teamManagement, Labs, commons } = Locale;
	const [modal, setModal] = useState(false);
	const [cities, setCities] = useState([]);
	const [countries, setCountries] = useState([]);

	const locale = localStorage.getItem("currentLocale") || "en";
	const toggle = () => setModal(!modal);
	const history = useHistory();
	const statues = [
		{ value: "active", label: Labs.active },
		{ value: "inactive", label: Labs.inactive },
	];

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);
		}

		fetchLookups();
	}, []);

	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCities(filter.country_id);
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCities(format);
		}
		fetchLookups();
	}, [filter.country_id]);

	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={filter.name}
						onChange={(e) => {
							setfilter({
								...filter,
								name: e.target.value,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={filter.country}
						placeholder={teamManagement.governorate}
						name="country"
						options={countries}
						onChange={(e) => {
							setfilter({
								...filter,
								country_id: e.value,
								country: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={filter.city}
						placeholder={teamManagement.city}
						name="city"
						options={cities}
						onChange={(e) => {
							setfilter({
								...filter,
								city_id: e.value,
								city: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={filter.status}
						placeholder={teamManagement.status}
						name="status"
						options={statues}
						onChange={(e) => {
							setfilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="btn-boxs">
					<div
						className=" border p-2 rounded mt-2 text-center"
						role="button"
						onClick={() => {
							setfilter({
								name: "",
								country: null,
								city: null,
								status: null,
							});
						}}
					>
						<span>{commons.reset}</span>
						<i class="fas fa-sync-alt m-1"></i>
					</div>

					<ShowForPermission permission="company:create-lab">
						<button
							onClick={() => history.push("/companies/lab-management/add")}
						>
							{teamManagement.addLab}
						</button>
					</ShowForPermission>
				</div>
			</div>
			<Modal
				className={`add-model ${activePage === 2 ? "w-55" : ""} `}
				isOpen={modal}
				toggle={toggle}
			>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">
						{activePage === 2
							? teamManagement.addGroup
							: teamManagement.addUser}
					</p>
				</div>

				<ModalBody>
					{activePage === 2 ? (
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-5">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<div className="card-group">
										<div className="box-title">
											<CheckBox />
											<h3>إدارة المعامل</h3>
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
										<div className="box-row">
											<CheckBox text={"إدارة المعامل"} />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="col-md-12">
							<div className="col-md-12">
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
								<div className="row">
									<TextField
										type="text"
										label={teamManagement.groupName}
										placeholder={teamManagement.groupName}
										// value={addInvitationState.companyName}
									/>
								</div>
							</div>
						</div>
					)}
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggle}>
						{teamManagement.add}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
