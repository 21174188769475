import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";

export default function Filter({ filter, setfilter }) {
	const { teamManagement, Labs, commons } = Locale;
	const statues = [
		{ value: "active", label: Labs.active },
		{ value: "inactive", label: Labs.inactive },
	];
	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={filter.name}
						onChange={(e) => {
							setfilter({
								...filter,
								name: e.target.value,
								page: 1,
							});
						}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						placeholder={teamManagement.status}
						name="status"
						options={statues}
						value={filter.status}
						onChange={(e) => {
							setfilter({
								...filter,
								status: e,
								page: 1,
							});
						}}
					/>
				</div>
			</div>
		</>
	);
}
