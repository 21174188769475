import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

const tenantURL = `https://${subdomain}.${domain}/api/tenant/companies`;

export const listTravellers = async (page = 1) => {
	// console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/insurance-policies?page=" + page)
		.then((res) => res)
		.catch((err) => err.response);
};

/* PCR Reservation  company*/

export const travelerPCRCompany = async (filter) => {
	return await axios
		.get(`${tenantURL}/covid`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res)
		.catch((err) => err.response);
};

/* export const startPCRAnalysis = async (id) => {
	return await axios
		.get(`${tenantURL}/labs/covid/start-analysis/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const savePCRResult = async (result) => {
	return await axios
		.post(`${tenantURL}/labs/covid/save-result`, result)
		.then((res) => res)
		.catch((err) => err.response);
};
 */
