import Model from "components/model";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { activateBranch } from "services/lab";
import Locale from "translations";

export default function Branch({
	branchList,
	setBranchList,
	toggle,
	getBranchesApi,
}) {
	const { teamManagement, Labs, users } = Locale;
	const history = useHistory();

	const [status, setStatus] = useState(
		branchList.status === "active" ? true : false
	);

	const toggleButton = async () => {
		const branchStatus = status ? "deactivate" : "activate";
		const activate = await activateBranch(branchList.id, branchStatus);
		if (activate.status === 200) {
			setStatus(!status);
		}

		// setStatus(!status);
	};

	const [deleteModal, setDeleteModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		active: false,
	});

	//Modal Delete
	const toggleDelete = () => {
		setDeleteModal({
			isOpen: !deleteModal.isOpen,
			type: "delete",
			title: "Delete Branch",
		});
	};

	//Delete Branch
	let myID = branchList.id;
	const deleteBranch = async () => {
		setDeleteModal({ ...deleteModal, active: true });
		const res = await deleteBranch(myID);
		if (res.status === 200) {
			setDeleteModal({});
			setBranchList(res.data.data.data);
		} else {
			setDeleteModal({ ...deleteModal, active: false, isOpen: false });
		}
	};

	return (
		<>
			<Model
				isOpen={deleteModal.isOpen}
				toggle={toggleDelete}
				type={deleteModal.type}
				title={deleteModal.title}
				submit={deleteBranch}
				disabled={deleteModal.active}
			>
				<div className="col p-3">{teamManagement.deleteBranch}</div>
			</Model>
			<tr key={branchList.id}>
				<td>{branchList.name}</td>
				<td>{branchList.address}</td>
				<td>{branchList.email}</td>
				<td>{branchList.phone_number}</td>
				<td>
					<i
						className={`far fa-2x ${
							status === true
								? "fa-check-circle text-success"
								: "text-danger fa-times-circle"
						}`}
					></i>
				</td>

				<td className="w-10">
					<button
						type="button"
						className="btn btn-link text-gray p-0"
						onClick={() =>
							history.push(`/lab/branches-management/${branchList.id}`)
						}
					>
						<i className="fas fa-eye"></i> {teamManagement.view}
					</button>
				</td>
				<td className="w-10">
					<button
						type="button"
						className="btn btn-link text-gray p-0"
						onClick={() => {
							toggle();
							getBranchesApi(branchList.id);
						}}
					>
						<i className="far fa-edit"></i> {teamManagement.edit}
					</button>
				</td>
				<td className="align-middle pointer" onClick={toggleButton}>
					<a
						className={`link-company pointer ${
							status === true ? "text-danger" : "text-success"
						}`}
					>
						<i
							className={`fas fa-fw ${status === true ? "fa-stop" : "fa-play"}`}
						></i>
						{status === true ? `${Labs.stop}` : `${Labs.play}`}
					</a>
				</td>

				{/* <td className="w-10">
					<button
						type="button"
						className="btn btn-danger"
						onClick={toggleDelete}
					>
						{users.remove}
					</button>
				</td> */}
			</tr>
		</>
	);
}
