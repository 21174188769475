import CheckBox from "components/shared/Chekbox";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { formatGetLab, formatPostLab } from "data/lab";
import validate, { isFormValid, validatePhone } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { createLab, editLab, getLab } from "services/lab";
import { fetchAreas, fetchCities, fetchCountries } from "services/lookups";
import Locale from "translations";

export default function AddLab() {
	let history = useHistory();
	const [readonly, setReadonly] = useState(null);

	const { teamManagement, companies, commons } = Locale;
	// const [time, setTime] = useState("10:00");
	const locale = localStorage.getItem("currentLocale") || "en";
	const days = [
		"sunday",
		"monday",
		"tuesday",
		"wednesday",
		"thursday",
		"friday",
		"saturday",
	];

	const [lab, setLab] = useState({
		name: "",
		capacity: "",
		fromHour: "",
		toHour: "",
		country: "",
		city: "",
		area: "",
		address: "",
		email: "",
		phone: "",
		userName: "",
		userEmail: "",
		userPhone: "",
		workDays: "",
	});

	const isAllDaysChecked = lab.workDays && lab.workDays.length === 7;
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [area, setArea] = useState([]);
	const [touchDay, setTouchDay] = useState(false);

	const { id, details } = useParams();

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "name", value: lab.name },
				{ required: true, min: 3 }
			),
			...validate({ name: "country", value: lab.country }, { required: true }),
			...validate({ name: "city", value: lab.city }, { required: true }),
			...validate({ name: "area", value: lab.area }, { required: true }),
			...validate(
				{ name: "capacity", value: lab.capacity },
				{ required: true }
			),
			...validate(
				{ name: "fromHour", value: lab.fromHour },
				{ required: true, formula: true }
			),
			...validate(
				{ name: "toHour", value: lab.toHour },
				{ required: true, formula: true }
			),
			...validate(
				{ name: "address", value: lab.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "email", value: lab.email },
				{ required: true, email: true }
			),

			...validate(
				{
					name: "phone",
					value: validatePhone(lab.phone, lab.country?.value),
				},
				{ required: true, phone: true }
			),
			...validate(
				{ name: "userName", value: lab.userName },
				{ required: true }
			),
			...validate(
				{ name: "userEmail", value: lab.userEmail },
				{ required: true }
			),
			...validate(
				{
					name: "userPhone",
					value: validatePhone(lab.userPhone, lab.country?.value),
				},
				{ required: true }
			),
			...validate(
				{ name: "workDays", value: lab.workDays },
				{ required: true }
			),
		});
	};
	/* Fetch countries */
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();
			const format = res.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));

			setCountries(format);
		}
		fetchLookups();
	}, []);

	/* Fetch cities */
	useEffect(() => {
		async function fetchLookups() {
			if (lab.country?.value) {
				const res = await fetchCities(lab.country?.value);
				const formatted = res.map((country) => ({
					value: country.id,
					label: country.names[locale],
				}));
				setCities(formatted);
			}
		}
		fetchLookups();
	}, [lab.country]);

	/* Fetch areas */
	useEffect(() => {
		async function fetchLookups() {
			if (lab.country?.value) {
				const res = await fetchAreas(lab.city?.value);
				const formatted = res.map((area) => ({
					value: area.id,
					label: area.names[locale],
				}));
				setArea(formatted);
			}
		}
		fetchLookups();
	}, [lab.city]);

	useEffect(async () => {
		if (isFormValid(errors)) {
			if (id && id != "add") {
				const data = formatPostLab(lab);
				const res = await editLab(data, id);
				if (res.status === 201) {
					history.push("/companies/lab-management");
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			} else {
				const data = formatPostLab(lab);
				const res = await createLab(data);
				if (res.status === 201) {
					history.push("/companies/lab-management");
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		}
	}, [isErrorLoaded]);

	useEffect(async () => {
		if (id && id != "add") {
			const res = await getLab(id);
			if (res.status === 200) {
				const formatted = formatGetLab(res.data.data);
				setLab(formatted);
			}
		}
		if (details === "details") {
			setReadonly(" ");
		}
	}, []);

	const handleChange = (e) => {
		if ("target" in e) {
			const value = e.target.value;
			const name = e.target.name;
			setLab({ ...lab, [name]: value });
		} else {
			const value = e.value;
			const name = e.name;
			if (name === "country") {
				setCities([]);
				setLab({ ...lab, city: "", [name]: value });
			} else setLab({ ...lab, [name]: value });
		}
	};

	const handleAllDays = (e) => {
		const checked = e.target.checked;
		const value = e.target.value;
		if (checked) {
			setLab({ ...lab, workDays: days });
		} else {
			setLab({ ...lab, workDays: [] });
		}
	};

	const handleDays = (e) => {
		// console.log("ee", e.target.value, e.target.checked);
		const checked = e.target.checked;
		const value = e.target.value;
		const day = days[value];
		setTouchDay(true);
		if (checked) {
			setLab({ ...lab, workDays: [...lab.workDays, day] });
		} else {
			const workDaysClone = [...lab.workDays];
			const filtered = workDaysClone.filter((d) => d !== day);
			setLab({ ...lab, workDays: filtered });
			/*--------------- */

			setErrors({
				...errors,
				...validate(e.target, {
					//checked: true,
					required: true,
				}),
			});
		}
	};

	const submit = () => {
		setTouchDay(true);
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	// console.log("----lab----", lab);
	// console.log("----err----", errors);

	return (
		<>
			<section className="bg-gray py-3 reservation-section">
				<div className="container-fluid">
					<div className="lab-info-box ">
						<div className="TeamManagement-header">
							<div className="TeamManagement-title">
								{" "}
								{teamManagement.labInfo}
							</div>
							<div className="TeamManagement-taps"></div>
						</div>
						<div className="col-md-12 p-d-20">
							<div className="row">
								<div className="col-md-6">
									<TextField
										disabled={readonly}
										type="text"
										label={teamManagement.labName}
										placeholder={teamManagement.labName}
										name="name"
										errors={errors.name}
										color={errors.name?.required ? "danger" : ""}
										min={3}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
										}}
										value={lab.name}
									/>
								</div>
								<div className="col-md-6">
									<SelectField
										disabled={readonly}
										isSearchable={!readonly}
										name="country"
										type="text"
										label={companies.country}
										placeholder={companies.country}
										errors={errors.country}
										color={errors.country?.required ? "danger" : ""}
										options={countries}
										onChange={(e) => {
											handleChange({ name: "country", value: e });
											setErrors({
												...errors,
												...validate(
													{ name: "country", value: e },
													{
														required: true,
													}
												),
											});
										}}
										value={lab.country}
									/>
								</div>

								<div className="col-md-6">
									<div className="col-md-12">
										<SelectField
											disabled={readonly}
											isSearchable={!readonly}
											type="text"
											name="city"
											label={companies.city}
											placeholder={companies.city}
											errors={errors.city}
											color={errors.city?.required ? "danger" : ""}
											options={cities}
											onChange={(e) => {
												handleChange({ name: "city", value: e });
												setErrors({
													...errors,
													...validate(
														{ name: "city", value: e },
														{
															required: true,
														}
													),
												});
											}}
											value={lab.city}
										/>
									</div>
								</div>

								<div className="col-md-6">
									<SelectField
										disabled={readonly}
										isSearchable={!readonly}
										name="area"
										type="text"
										label={teamManagement.area}
										placeholder={teamManagement.areaPlaceholder}
										errors={errors.country}
										color={errors.country?.required ? "danger" : ""}
										options={area}
										onChange={(e) => {
											handleChange({ name: "area", value: e });
											setErrors({
												...errors,
												...validate(
													{ name: "area", value: e },
													{
														required: true,
													}
												),
											});
										}}
										value={lab.area}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										disabled={readonly}
										type="text"
										name="phone"
										extraTextPosition={"prepend"}
										errors={errors.phone}
										color={errors.phone?.required ? "danger" : ""}
										extraText={
											lab.country.value ? "+" + lab.country.value : "---"
										}
										label={companies.phone}
										value={lab.phone}
										placeholder={companies.phone}
										onChange={(e) => {
											handleChange(e);

											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													phone: true,
												}),
											});
										}}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										disabled={readonly}
										type="text"
										name="email"
										label={companies.email}
										errors={errors.email}
										color={errors.email?.required ? "danger" : ""}
										placeholder={companies.email}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
										}}
										value={lab.email}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										disabled={readonly}
										type="text"
										name="userName"
										label={companies.userName}
										errors={errors.userName}
										color={errors.userName?.required ? "danger" : ""}
										placeholder={companies.userName}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
												}),
											});
										}}
										value={lab.userName}
									/>
								</div>
								<div className="col-md-6">
									<TextField
										disabled={readonly}
										type="text"
										name="userEmail"
										label={companies.userEmail}
										errors={errors.userEmail}
										color={errors.userEmail?.required ? "danger" : ""}
										placeholder={companies.userEmail}
										onChange={(e) => {
											handleChange(e);
											setErrors({
												...errors,
												...validate(e.target, {
													required: true,
													email: true,
												}),
											});
										}}
										value={lab.userEmail}
									/>
								</div>
								<div className="col-md-6">
									<div className="col-md-12">
										<TextField
											disabled={readonly}
											type="text"
											name="address"
											label={teamManagement.address}
											placeholder={teamManagement.address}
											errors={errors.address}
											color={errors.address?.required ? "danger" : ""}
											min={3}
											onChange={(e) => {
												handleChange(e);
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														min: 3,
													}),
												});
											}}
											value={lab.address}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="lab-info-box ">
						<div className="TeamManagement-header">
							<div className="TeamManagement-title">
								{" "}
								{teamManagement.operatingData}
							</div>
							<div className="TeamManagement-taps"></div>
						</div>
						<div className="col-md-12 p-d-20">
							<div className="row work-days">
								<div className="col-md-2 text-label">
									<label htmlFor=""> {teamManagement.workDays}</label>
								</div>
								<div className="col-md-9">
									<div className="day">
										<CheckBox
											disabled={readonly}
											id={teamManagement.allDays}
											text={teamManagement.allDays}
											onChange={handleAllDays}
											checked={isAllDaysChecked}
										/>
									</div>
									{days &&
										!isAllDaysChecked &&
										// lab.workDays.length !== 7 &&
										days.map((day, i) => {
											return (
												<div key={day} className="day">
													<CheckBox
														id={days[i]}
														disabled={readonly}
														text={days[i]}
														value={i}
														checked={lab.workDays.includes(day)}
														name="check"
														label={companies.check}
														errors={errors.check}
														color={errors.check?.required ? "danger" : ""}
														onChange={handleDays}
													/>
												</div>
											);
										})}
									{touchDay && lab.workDays.length === 0 ? (
										<div className="text-danger">{commons.isRequired}</div>
									) : (
										""
									)}
									<div></div>
								</div>
							</div>

							<div className="row work-days">
								<div className="col-md-2 text-label">
									<label htmlFor=""> {teamManagement.operatingHours}</label>
								</div>
								<div className="col-md-9">
									<div className="row">
										<div className="col-md-3">
											<TextField
												disabled={readonly}
												type="text"
												name="fromHour"
												label={teamManagement.from}
												placeholder={"من HH:MM"}
												errors={errors.fromHour}
												color={errors.fromHour?.required ? "danger" : ""}
												onChange={(e) => {
													handleChange(e);
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
															formula: true,
														}),
													});
												}}
												value={lab.fromHour}
											/>
										</div>
										<div className="col-md-3">
											<TextField
												disabled={readonly}
												type="text"
												name="toHour"
												label={teamManagement.to}
												placeholder={"من HH:MM"}
												errors={errors.toHour}
												color={errors.toHour?.required ? "danger" : ""}
												onChange={(e) => {
													handleChange(e);
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
															formula: true,
														}),
													});
												}}
												value={lab.toHour}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="row work-days">
								<div className="col-md-2 text-label">
									<label htmlFor=""> {teamManagement.capacityPerHour}</label>
								</div>
								<div className="col-md-9">
									<div className="row">
										<div className="col-md-3">
											<TextField
												disabled={readonly}
												type="text"
												name="capacity"
												label={teamManagement.capacity}
												placeholder={teamManagement.capacityPerHour}
												errors={errors.capacity}
												color={errors.capacity?.required ? "danger" : ""}
												onChange={(e) => {
													handleChange(e);
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
														}),
													});
												}}
												value={lab.capacity}
											/>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="row work-days">
								<div className="col-md-2 text-label">
									<label htmlFor=""> {teamManagement.capacityPerHour}</label>
								</div>
								<div className="col-md-9">
									<div className="row">
										<div className="col-md-3">
											<TimePicker onChange={setTime} value={time} />
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
				{details != "details" && (
					<div className="col-md-12 p-d-15 over-flow">
						<Button onClick={submit} className="btn btn-add float-left-local">
							{id === "add" ? teamManagement.add : teamManagement.edit}
						</Button>
					</div>
				)}
			</section>
		</>
	);
}
