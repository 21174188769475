import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import Locale from "translations";
function Traveler({
	hotel,
	travellers,
	toggle,
	startAnalysis,
	examinationResult,
	setExaminationResult,
}) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { groups, Labs, teamManagement } = Locale;
	const { lab_id } = useParams();

	return (
		<>
			<tr>
				<td scope="col">{hotel.Hotel.Area.name}</td>
				<td scope="col">{hotel.Hotel.Name}</td>
				<td scope="col">
					<p className="text-success font-weight-bold">
						{moment(hotel.Hotel.EntryDate, "YYYY-MM-DD ").format("DD-MM-yyyy")}
					</p>
					<p className="text-danger font-weight-bold">
						{moment(hotel.Hotel.ExitDate, "YYYY-MM-DD ").format("DD-MM-yyyy")}
					</p>
				</td>

				<td scope="col">{travellers.PassportNum}</td>
				<td scope="col">{travellers.TravellerName}</td>
				<td scope="col">{travellers.Nin}</td>
				<td scope="col">{travellers.Gender}</td>
				<td scope="col">{travellers.Age}</td>
				<td scope="col">
					{" "}
					<button
						className="btn btn-success p-1"
						onClick={() => {
							setExaminationResult({
								...examinationResult,
								lab_id: lab_id,
								package_id: hotel.Id,
								package_name: hotel.Name,
								return_date: hotel.ReturnDate,
								company_name: JSON.stringify(hotel.CompanyName),
								company_code: hotel.CompanyCode,
								traveller_name: travellers.TravellerName,
								passport_num: travellers.PassportNum,
								national_id: travellers.Nin,
								nationality: travellers.Nationality,
								gender: travellers.Gender,
								age: travellers.Age,
								hotel_name: hotel.Hotel.Name,
								hotel_city: JSON.stringify(hotel.Hotel.City),
								hotel_area: JSON.stringify(hotel.Hotel.Area),
								hotel_address: hotel.Hotel.Address,
							});
							toggle();
						}}
					>
						<div className="d-flex align-items-center ">
							<p className="font-weight-bold">{groups.enterResult}</p>{" "}
							<i className="fa fa-check-circle m-1"></i>
						</div>
					</button>
				</td>
			</tr>
		</>
	);
}

export default Traveler;
