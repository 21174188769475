import React from "react";
import Locale from "translations";
import User from "./User";

export default function ListUsers(props) {
	const { user } = props;
	const { companies } = Locale;

	// console.log(user.data.data);
	const AllUsers =
		user && user?.data?.length > 0
			? user?.data?.map((user, index) => (
					<User
						user={user}
						index={index}
						changeStatusUser={props.changeStatusUser}
						togglePassword={props.togglePassword}
						toggleEdit={props.toggleEdit}
						// removeItem={removeItem}
					/>
			  ))
			: "";

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col"> {companies.userName}</th>
									<th scope="col"> {companies.userEmail}</th>
									<th scope="col"> {companies.useGroup}</th>
									<th scope="col"> {companies.status}</th>
									<th colSpan="4" scope="col">
										{companies.processes}
									</th>
								</tr>
							</thead>
							<tbody>{AllUsers}</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
