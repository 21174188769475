import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function Filter({ activePage }) {
	const { teamManagement } = Locale;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				<div className="filter-box">
					<SelectField
						hasLabel={false}
						value={""}
						placeholder={teamManagement.status}
						name="country"
						options={["countries"]}
						onChange={(e) => {}}
					/>
				</div>

				<div className="btn-boxs">
					<button onClick={toggle}>{teamManagement.addUser}</button>
				</div>
			</div>

			<Modal className={`add-model`} isOpen={modal} toggle={toggle}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{teamManagement.addUser}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="col-md-12">
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.userName}
									placeholder={teamManagement.enterUserName}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.email}
									placeholder={teamManagement.enterEmail}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<SelectField
									type="text"
									label={teamManagement.groupName}
									placeholder={teamManagement.groupName}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="row">
								<TextField
									type="text"
									label={teamManagement.phoneNumber}
									placeholder={"0110000000000000"}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggle}>
						{teamManagement.add}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
