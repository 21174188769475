import React from "react";
import Activity from "./Activity";
import Counts from "./Counts";
import TravelersNumber from "./TravelersNumber";

export default function DashboardCompanies() {
	return (
		<>
			<section className="dashboard-companies">
				<div className="container-fluid">
					<div className="row px-5 pt-4">
						<div className="col-md-3">
							<Activity />
						</div>
						<div className="col-md-6">
							<div className="bg-white shadow-sm h-100  p-2 font-weight-bold">
								<p className="text">
									<TravelersNumber />
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<Counts />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
