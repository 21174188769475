import React, { useEffect, useState } from "react";
import { fetchCountries } from "services/lookups";
import Filter from "./Filter";
import List from "./List";

export default function Company() {
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const countries = await fetchCountries();
			setCountries(countries);
		}
		fetchData();
	}, []);

	return (
		<>
			<section className="bg-gray py-3 medical-section">
				<div className="container-fluid">
					<div className="company-header">
						<div className="company-title"> الشركات</div>
					</div>
					<Filter countries={countries} setCountries={setCountries} />
					<List />
				</div>
			</section>
		</>
	);
}
