import TextField from "components/shared/TextField";
import validate, { isFormValid } from "helpers/validate";
import React, { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { changeLabUserInfo, LabsUser } from "services/lab";
import Locale from "translations";

function EditUser() {
	let history = useHistory();
	const locale = localStorage.getItem("currentLocale") || "en";
	const { teamManagement, users, commons } = Locale;
	const { labId, userId } = useParams();
	const [user, setUser] = useState();
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	/* Get User Info  */
	useEffect(async () => {
		const user = await LabsUser(labId, userId);
		setUser(user.data.data);
	}, []);

	/* Send Changes To BackEnd */
	useEffect(() => {
		const changeUserInfo = async () => {
			if (isFormValid(errors)) {
				const res = await changeLabUserInfo(labId, userId, { name: user.name });
				if (res.status == 201) {
					store.addNotification({
						title: "info!",
						message: res.data.message,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
				history.push("/companies/lab-management");
			}
		};
		changeUserInfo();
	}, [isErrorLoaded]);

	const submit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{
					name: "name",
					value: user.name,
				},
				{
					required: true,
					min: 3,
				}
			),
		});
	};

	return (
		<>
			<section>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-6">
							<TextField
								type="text"
								label={users.userName}
								placeholder={users.userNamePlaceholder}
								name="name"
								min={3}
								errors={errors.name}
								color={errors.name?.required ? "danger" : ""}
								value={user?.name}
								onChange={(e) => {
									// user.name = e.target.value;
									setUser({ ...user, name: e.target.value });
									setErrors({
										...errors,
										...validate(e.target, {
											required: true,
											min: 3,
										}),
									});
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-12 p-d-15 over-flow">
					<button onClick={submit} className="btn btn-add float-left-local">
						{teamManagement.edit}
					</button>
				</div>
			</section>
		</>
	);
}

export default EditUser;
