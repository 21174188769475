import moment from "moment";
import React from "react";
import Locale from "translations";

function Traveler({ traveler, index }) {
	const locale = localStorage.getItem("currentLocale") || "en";
	const { groups, Labs, teamManagement } = Locale;
	return (
		<>
			<tr>
				<td scope="col">{traveler.traveller.traveller_name}</td>
				<td scope="col">{traveler.traveller.passport_num}</td>
				<td scope="col">{traveler.lab.name}</td>
				<td scope="col">{traveler.traveller.nin}</td>
				<td scope="col">{traveler.traveller.gender}</td>
				<td scope="col">{traveler.traveller.age}</td>
				<td scope="col">
					<p>
						{moment(traveler.examination_date, "DD-MM-YYYY HH:mm aa").format(
							"DD-MM-yyyy"
						)}
					</p>
					<p>
						{moment(traveler.examination_date, "DD-MM-YYYY HH:mm aa").format(
							"HH:mm"
						)}
					</p>
				</td>
				<td scope="col">
					{traveler.examination.analysis_date ? (
						<div>
							<p className="text-success font-weight-bold">
								{Labs.ExaminationDone}
							</p>
							{moment(
								traveler.examination.analysis_date,
								"DD-MM-YYYY HH:mm aa"
							).format("DD-MM-yyyy")}
						</div>
					) : (
						<i className="fa fa-window-minimize text-secondary"></i>
					)}

					<p>
						{traveler.examination.analysis_date
							? moment(
									traveler.examination.analysis_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("HH:mm")
							: " "}
					</p>
				</td>
				<td scope="col">
					<p
						className={`${
							traveler.examination.status === "negative"
								? "text-success font-weight-bold"
								: traveler.examination.status === "positive"
								? "text-danger font-weight-bold"
								: "text-secondary "
						}`}
					>
						{traveler.examination.status}
					</p>
					<p>
						{traveler.examination.result_date
							? moment(
									traveler.examination.result_date,
									"DD-MM-YYYY HH:mm aa"
							  ).format("DD-MM-yyyy")
							: " "}
					</p>
				</td>
			</tr>
		</>
	);
}

export default Traveler;
