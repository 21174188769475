import axios from "axios";
import React, { useEffect, useState } from "react";
import Locale from "translations";

export default function ListSections() {
	const { Labs, branches } = Locale;

	const [branch, setBranch] = useState([]);

	useEffect(() => {
		axios.get("../../travelers.json").then((res) => {
			setBranch(res.data.Travelers);
		});
	}, []);

	// const AllBranches = branch.map((branch, index) => (
	// 	<Branch
	// 		branch={branch}
	// 		index={index}
	// 		// removeItem={removeItem}
	// 	/>
	// ));

	return (
		<>
			<div className="tab-content" id="pills-tabContent">
				<div
					className="tab-pane show active"
					id="pills-home"
					role="tabpanel"
					aria-labelledby="pills-home-tab"
				>
					<div className="reservation-table">
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th scope="col">{branches.branch}</th>
									<th scope="col">{branches.address}</th>
									<th scope="col">{branches.email}</th>
									<th scope="col">{branches.mobileNumber}</th>
									<th scope="col">{branches.status}</th>
									<th className="text-center" colSpan="3" scope="col">
										{branches.processes}
									</th>
								</tr>
							</thead>
							<tbody>
								{/* {AllBranches.length > 0 ? (
									AllBranches
								) : (
									<tr>
										<td colSpan="11">
											<div className="product-build__product-no-data">
												<i className="fas fa-info-circle fa-lg"></i>{" "}
												<h4>{Labs.noResult}</h4>
											</div>
										</td>
									</tr>
								)} */}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
