import { useState } from "react";
import { useParams } from "react-router";
import Locale from "translations";
import Filter from "./Filter";
import List from "./List";

export default function Index() {
	const { Labs } = Locale;
	const [amount, setAmount] = useState({});
	const [policie, setPolicie] = useState({});
	const { status } = useParams();

	const [filter, setfilter] = useState({
		national_id: "",
		passport_number: "",
		request_date: "",
		gender: null,
	});

	return (
		<>
			<section className="bg-gray py-3 medical-section">
				<div className="container-fluid">
					<Filter filter={filter} setfilter={setfilter} statues={status} />
					<List
						filter={filter}
						status={status}
						policie={policie}
						setPolicie={setPolicie}
						amount={amount}
						setAmount={setAmount}
						setfilter={setfilter}
					/>
				</div>
			</section>
		</>
	);
}
