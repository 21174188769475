import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
const [protocol, domain] = baseURL.split("//");

const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;
const tenantURL = `https://${subdomain}.${domain}`;

export const listTravellers = async () => {
	console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/api/tenant/branches/travellers/list")
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const listBranches = async () => {
	console.log("tenantURL", tenantURL);
	return await axios
		.get(tenantURL + "/api/tenant/branches/travellers/list")
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const checkStatus = async (data) => {
	console.log("tenantURL", tenantURL);
	return await axios
		.post(tenantURL + "/api/tenant/branches/travellers/check-status", data)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const StoreResult = async (data) => {
	console.log("tenantURL", tenantURL);
	return await axios
		.post(tenantURL + "/api/tenant/branches/travellers/store-result", data)
		.then((res) => res.data)
		.catch((err) => err.response);
};
