import React from "react";
import { Redirect, Route } from "react-router-dom";

// React Component
export function AuthRoute({ component: Component, ...props }) {
	const isAuth = localStorage.getItem("isAuth");
	const userType = localStorage.getItem("userType");
	console.log("userType", userType);

	const redirectTo = () => {
		switch (userType) {
			case "company-super-admin":
				return "/companies";
			case "lab-super-admin":
				return "/lab";
			case "branch-super-admin":
				return "/branches";
			case "null":
				return "/admin";
			default:
				return "/";
		}
	};

	if (!isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					// <Layout>
					<Component {...matchProps} />
					// </Layout>
				)}
			/>
		);
	} else {
		return <Redirect to={redirectTo()} />;
	}
}
