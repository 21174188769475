import uploadImg from "assets/images/labs/cloud-computing.png";
import userImg from "assets/images/labs/userImg.png";
import SelectField from "components/shared/SelectField";
import React, { useRef, useState } from "react";
import { store } from "react-notifications-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { checkStatus, StoreResult } from "services/branch";
import Locale from "translations";

export default function Traveler({ traveler, fetchData }) {
	const { Labs } = Locale;
	const [modal, setModal] = useState(false);
	const toggleModal = () => setModal(!modal);
	const [fileType, setFileType] = useState({});
	const listStatus = [
		{
			label: "Negative",
			value: "negative",
		},
		{
			label: "Positive",
			value: "positive",
		},
	];
	const [result, setResult] = useState({});
	const inputFileRef = useRef(null);
	const handleBtnClick = () => {
		inputFileRef.current.click();
	};

	const handleChangeStatus = async (event, traveller_reservation_id) => {
		if (event == 1) {
			const res = await checkStatus({
				traveller_reservation_id: traveller_reservation_id,
				status: 0,
			});
			if (res.status) {
				fetchData();
				store.addNotification({
					title: "info!",
					message: res.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		} else {
			const res = await checkStatus({
				traveller_reservation_id: traveller_reservation_id,
				status: 1,
			});
			if (res.status) {
				fetchData();
				store.addNotification({
					title: "info!",
					message: res.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const onFileChange = async (e) => {
		setFileType(e.target.files[0]);

		// formData.append("bucket", "company");
		// if (!fileType.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
		// 	store.addNotification({
		// 		title: "commons.somethingWentWrong",
		// 		message: " companyAvatar.companyAvatarExtensionValidation",
		// 		type: "danger",
		// 		insert: "top",
		// 		container: "top-right",
		// 		animationIn: ["animated", "fadeIn"],
		// 		animationOut: ["animated", "fadeOut"],
		// 		dismiss: {
		// 			duration: 3000,
		// 			onScreen: true,
		// 			pauseOnHover: true,
		// 		},
		// 	});
		// } else if (fileType.size > 500000) {
		// 	store.addNotification({
		// 		title: "commons.somethingWentWrong",
		// 		message: "companyAvatar.companyAvatarSizeValidation",
		// 		type: "danger",
		// 		insert: "top",
		// 		container: "top-right",
		// 		animationIn: ["animated", "fadeIn"],
		// 		animationOut: ["animated", "fadeOut"],
		// 		dismiss: {
		// 			duration: 3000,
		// 			onScreen: true,
		// 			pauseOnHover: true,
		// 		},
		// 	});
		// }
	};
	const SaveResults = async (traveller_reservation_id) => {
		const formData = new FormData();
		formData.append("file", fileType);
		// formData.append("name", fileType.name);
		formData.append("traveller_reservation_id", traveller_reservation_id);
		formData.append("examing_status", result.value);
		const response = await StoreResult(formData);
		if (response.status) {
			toggleModal(false);
			fetchData();
			store.addNotification({
				title: "info!",
				message: response.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<Modal
				size="lg"
				isOpen={modal}
				toggle={toggleModal}
				// className={className}
			>
				<ModalHeader className="d-flex align-items-center justify-content-center ">
					<h5 className=" font-weight-bold">Enter the PCR result</h5>
				</ModalHeader>
				<ModalBody className="mx-3">
					<div className="mybooking-input-wrapper">
						<SelectField
							label="PCR Result"
							options={listStatus}
							onChange={(value) => {
								setResult({
									...value,
								});
							}}
							value={result ? result : ""}
							placeholder={"PCR Result"}
						/>
					</div>
					<div className="input-upload">
						<label for="signup-upload">
							<div className="img-box">
								<div className="shape">
									<img type="file" src={uploadImg} alt="IconImg" />
									<div className="btn gold-color-bg white-color font-15 px-3 my-1 text-white">
										File selection
									</div>
								</div>
							</div>
						</label>
						<input
							type="file"
							ref={inputFileRef}
							onChange={onFileChange}
							className="d-none"
							id="signup-upload"
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModal}
					>
						Back
					</Button>
					<Button
						className="px-5 w-30"
						onClick={() => {
							SaveResults(1);
						}}
						color="success"
					>
						Save
					</Button>{" "}
				</ModalFooter>
			</Modal>

			<tr>
				<th scope="row">
					<img src={userImg} alt="Img" width="50" height="50" />
				</th>
				<td>{traveler.traveller_name}</td>
				<td>{traveler.passport_num}</td>
				<td>{traveler.nin}</td>
				<td>{traveler.gender}</td>
				<td>{traveler.age}</td>
				<td>{traveler.departure_date}</td>

				{traveler.status === 1 ? (
					<td className="text-success text-center">
						<i className="fas fa-check px-1"></i>
						{Labs.checked}
					</td>
				) : (
					<td className="text-center">لم يتم الفحص</td>
				)}

				<td>
					<p>{traveler.departure_date}</p>
					<p>{traveler.examination_date}</p>
				</td>
				<td className="align-middle">
					{traveler.status === 0 ? ` - ` : `${traveler.examing_status}`}
					{/* {traveler.examing_status} */}
				</td>
				<td className="d-flex align-items-center">
					<div className="custom-control custom-checkbox ">
						<input
							onChange={(event) =>
								handleChangeStatus(
									traveler.status,
									traveler.traveller_reservation_id
								)
							}
							checked={traveler.status}
							className="custom-control-input permChecks"
							id="classification-2"
							type="checkbox"
							value="1"
						/>
						<label
							className="custom-control-label"
							for="classification-2"
						></label>
					</div>

					<button
						disabled={traveler.status == 0}
						type="button"
						className="btn gold-color-bg text-white font-12"
						data-toggle="modal"
						data-target="#exampleModal"
						onClick={toggleModal}
					>
						{Labs.enterResult}
					</button>
				</td>
			</tr>
		</>
	);
}
