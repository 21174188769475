const locale = localStorage.getItem("currentLocale") || "en";
export function formatPostCompany(company) {
	const {
		name,
		domain,
		email,
		phone,
		country,
		city,
		address,
		website,
		userName,
		userEmail,
		userPhone,
		userId,
	} = company;

	return {
		name,
		domain_name: domain,
		email,
		phone_number: `+${country.value}${phone}`,
		country_id: country.value,
		city_id: city.value,
		user_country_id: country.value,
		website,
		user_name: userName,
		user_email: userEmail,
		user_phone_number: `+${country.value}${userPhone}`,
		address,
		user_id: userId,
	};
}

export function formatGetCompany(company) {
	const {
		name,
		domain_name,
		email,
		phone_number,
		country,
		city,
		website,
		user,
		address,
		country_id,
	} = company;
	const [_, phone] = phone_number.split(country_id);

	return {
		name: name,
		domain: domain_name,
		email,
		phone,
		country: { value: country.id, label: country.names[locale] },
		city: { value: city.id, label: city.names[locale] },
		address,
		website,
		userName: user.name,
		userEmail: user.email,
		userPhone: user.phone_number,
		userId: user.id,
	};
}
