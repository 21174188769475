import React from "react";

export default function CheckBox(props) {
	return (
		<>
			<input
				disabled={props.disabled}
				type="Checkbox"
				name={props.name}
				value={props.value}
				checked={props.checked}
				change={props.change}
				onChange={props.onChange}
				id={props.id}
				className={props.className}
			/>

			<label
				className="form-check-label pointer text-caption mx-2 "
				htmlFor={props.id}
			>
				{props.text}
			</label>
		</>
	);
}
