import ShowForPermission from "helpers/showForPermission";
import React from "react";
import Locale from "translations";

export default function User(props) {
	const { user } = props;
	const { Labs } = Locale;

	return (
		<>
			<tr>
				<td>{user.name}</td>
				<td>{user.email}</td>
				<td> {user.group}</td>
				<td>
					{user.status == "active" ? (
						<i className="far fa-check-circle btn fa-2x text-success"></i>
					) : (
						<i class="far fa-times-circle btn fa-2x text-danger"></i>
					)}
				</td>

				<td className="w-10">
					<ShowForPermission permission="company:edit-user">
						<button
							type="button"
							onClick={() => props.toggleEdit(user.id)}
							className="btn btn-link text-gray"
						>
							<i className="far fa-edit"></i> {Labs.edit}
						</button>
					</ShowForPermission>
				</td>
				<td className="w-10">
					{user.status == "active" ? (
						<ShowForPermission permission="company:deactivate-user">
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "deactivate")}
								className="btn btn-link text-danger"
							>
								<i className="fas fa-stop"></i>
								{Labs.deactivate}
							</button>
						</ShowForPermission>
					) : (
						<ShowForPermission permission="company:activate-user">
							<button
								type="button"
								onClick={() => props.changeStatusUser(user.id, "activate")}
								className="btn btn-link text-success"
							>
								<i class="far fa-play-circle"></i>
								{Labs.active}
							</button>
						</ShowForPermission>
					)}
				</td>
				<td className="w-10">
					<ShowForPermission permission="company:change-user-password">
						<button
							type="button"
							onClick={() => props.togglePassword(user.id)}
							className="text-gray btn btn-link "
						>
							<i className="fas fa-unlock-alt"></i>
							{Labs.changePassword}
						</button>
					</ShowForPermission>
				</td>
				<td className="w-10">
					{/* <button type="button" className="btn btn-danger">
						حذف
					</button> */}
				</td>
			</tr>
		</>
	);
}
