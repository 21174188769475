import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import React from "react";
import Locale from "translations";
import AddGroup from "./groups/AddGroup";
import AddSection from "./sections/AddSection";
import AddUser from "./users/AddUser";
export default function Filter({ activePage, toggle, modal }) {
	const { teamManagement, branches, users } = Locale;

	return (
		<>
			<div className="filter-container">
				<div className="filter-box">
					<TextField
						type="text"
						placeholder={teamManagement.search}
						hasLabel={false}
						value={""}
						onChange={(e) => {}}
					/>
				</div>

				{activePage == 1 ? (
					<div className="filter-box">
						<SelectField
							hasLabel={false}
							value={""}
							placeholder={teamManagement.status}
							name="country"
							options={["countries"]}
							onChange={(e) => {}}
						/>
					</div>
				) : null}

				<div className="btn-boxs">
					{activePage === 1 ? (
						<AddUser toggle={toggle} modal={modal} />
					) : activePage === 2 ? (
						<AddSection />
					) : (
						<AddGroup />
					)}
				</div>
				<div className="clearfix"></div>
			</div>
		</>
	);
}
