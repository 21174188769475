import CheckBox from "components/shared/Chekbox";
import TextField from "components/shared/TextField";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Locale from "translations";
export default function AddGroup({ activePage }) {
	const { teamManagement, groups } = Locale;
	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const history = useHistory();
	return (
		<>
			<button onClick={toggle}>{groups.addGroup}</button>
			<Modal className="add-model w-55" isOpen={modal} toggle={toggle}>
				<div className="col-md-12 box-title-model">
					<p className="text-center title-model">{teamManagement.addGroup}</p>
				</div>

				<ModalBody>
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6">
								<TextField
									type="text"
									label={groups.groupName}
									placeholder={groups.groupNamePlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									type="text"
									label={groups.branch}
									placeholder={groups.branchPlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
							<div className="col-md-3">
								<TextField
									type="text"
									label={groups.section}
									placeholder={groups.sectionPlaceholder}
									// value={addInvitationState.companyName}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<div className="card-group">
									<div className="box-title pointer text-caption">
										<CheckBox text={`${groups.userManagement}`} id={"one"} />
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.addUser}`} id={"two"} />
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.editUser}`} id={"three"} />
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.viewUserData}`} id={"four"} />
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card-group">
									<div className="box-title pointer text-caption">
										<CheckBox
											text={`${groups.manageGroupPermissions}`}
											id={"five"}
										/>
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.addUser}`} id={"six"} />
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.editUser}`} id={"seven"} />
									</div>
									<div className="box-row">
										<CheckBox text={`${groups.viewGroups}`} id={"eight"} />
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card-group">
									<div className="box-title pointer text-caption">
										<CheckBox
											text={`${groups.examinationStatus}`}
											id={"nine"}
										/>
									</div>
									<div className="box-row">
										<CheckBox
											text={`${groups.changeExaminationStatus}`}
											id={"ten"}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="card-group">
									<div className="box-title pointer text-caption">
										<CheckBox text={`${groups.enterResult}`} id={"eleven"} />
									</div>
									<div className="box-row">
										<CheckBox
											text={`${groups.addResultAnalysis}`}
											id={"twelve"}
										/>
									</div>
									<div className="box-row">
										<CheckBox
											text={`${groups.editResultAnalysis}`}
											id={"thirteen"}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button className="btn btn-cancel" onClick={toggle}>
						{teamManagement.cancel}
					</Button>
					<Button className="btn btn-add" onClick={toggle}>
						{teamManagement.add}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}
