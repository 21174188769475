import { useLabsState } from "context/global";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Locale from "translations";

export default function DatePickerField(props) {
	const [focused, setFocused] = useState(false);
	const { locale } = useLabsState();
	const { commons } = Locale;

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "date" && v) {
					message.push(commons.shouldBeValid);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			<div
				className={`my-2 control-field + ${
					props.color ? " control-field--" + props.color : ""
				}`}
			>
				{/* Date Range Body */}
				<div
					className={`control-field__body position-relative ${
						props.disabled && props.readOnly ? "isDisabled" : ""
					}`}
				>
					{/* Label */}
					{props.hasLabel ? (
						<label
							className={`control-field__label ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</label>
					) : null}

					{/* Date Inputs */}
					<div className="row no-gutters justify-content-between">
						<SingleDatePicker
							numberOfMonths={1}
							onOutsideClick={true}
							date={props.date}
							noBorder={true}
							focused={focused}
							displayFormat={() => "DD/MM/YYYY"}
							onFocusChange={({ focused }) => setFocused(focused)}
							onDateChange={props.onChangeDate}
							placeholder={props.placeholder}
							hideKeyboardShortcutsPanel={true}
							customArrowIcon={true}
							isRTL={locale === "en" ? false : true}
							anchorDirection={locale === "en" ? "left" : "right"}
							{...props}
						/>
						<i className="fas fa-calendar-alt date-icon p-2"></i>
					</div>
				</div>
			</div>

			{/* Feedback */}
			{/* <small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small> */}
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
}

DatePickerField.defaultProps = {
	hasLabel: true,
};
