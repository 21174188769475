import React, { useEffect, useState } from "react";
import { listCompanies } from "services/admin";
import Locale from "translations";
import Company from "./Company";

export default function List() {
	const { Labs } = Locale;
	const [companies, setCompanies] = useState([]);
	// const [countries, setCountries] = useState([]);

	useEffect(() => {
		async function fetchData() {
			const companies = await listCompanies();
			setCompanies(companies.data);
		}
		fetchData();
	}, []);

	// console.log("---", companies);

	const AllCompanies =
		companies.length > 0 &&
		companies.map((company, index) => (
			<Company
				company={company}
				index={index}
				// removeItem={removeItem}
			/>
		));

	return (
		<>
			<div className="medical-result">
				<div className="no-result d-none">
					<i className="fas fa-exclamation-triangle"></i>
					<p>لا توجد نتائج مطابقة للبحث</p>
				</div>

				<section className="reservation-section">
					<div className="reservation-box m-0">
						<div className="tab-content" id="pills-tabContent">
							<div
								className="tab-pane show active"
								id="pills-home"
								role="tabpanel"
								aria-labelledby="pills-home-tab"
							>
								<div className="reservation-table">
									<table className="table table-striped mb-0">
										<thead>
											<tr className="bg-grey text-white">
												<th scope="col">{Labs.company}</th>
												<th scope="col">{Labs.email}</th>
												<th scope="col">{Labs.country}</th>
												<th scope="col">{Labs.city}</th>
												<th scope="col">{Labs.phoneNumber}</th>
												<th scope="col">{Labs.websiteLink}</th>
												<th scope="col">{Labs.status}</th>
												<th scope="col">{Labs.DateOfContract}</th>
												<th colSpan="4" scope="col">
													{Labs.tools}
												</th>
											</tr>
										</thead>
										<tbody>
											{AllCompanies.length > 0 ? (
												AllCompanies
											) : (
												<tr>
													<td colSpan="11">
														<div className="product-build__product-no-data">
															<i className="fas fa-info-circle fa-lg"></i>{" "}
															<h4>{Labs.noResult}</h4>
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
