import React from "react";
import Locale from "translations";
import Group from "./Group";

export default function GroupTable() {
	const { teamManagement, groups } = Locale;
	return (
		<>
			<div className="content-team">
				<div className="collapse show">
					<div className="card card-body p-0 mt-3">
						<div className="table-responsive "></div>
						<table className="table table-striped mb-0">
							<thead>
								<tr className="bg-grey text-white">
									<th>{groups.userName}</th>
									<th>{groups.branch}</th>
									<th>{groups.email}</th>
									<th>{groups.status}</th>
								</tr>
							</thead>
							<tbody>
								<Group />
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
